import { authConstants } from "../../constants/authenticationConstants";
import { push } from "react-router-redux";
import {
	authHeader,
	getLoggedUser,
	handleResponse,
	handleError
} from "../../helpers";
import {notify} from '../../helpers/notification';
import { config } from "../../helpers/config";

export const authenticationActionCreators = {
	checkGuid: guid => async dispatch => {
		const url = `${config.base}api/Authentication/CheckGuid?guid=${guid}`;

		return fetch(url, {
			method: "GET"
		}).then(res => {
			if (!res.ok) {
				dispatch(push("/"));
				dispatch({ type: authConstants.POST_ERROR });
			}
		});
	},

	sendResetPasswordEmail: () => async (dispatch, getState) => {
		const { form } = getState();
		const passForm = form.ForgotPasswordForm.values;
		const email = passForm.email.trim();
		const url = `${
			config.base
		}api/Authentication/SendResetPassEmail?email=${email}`;

		return fetch(url, {
			method: "GET"
		}).then(res => {
			if (res.ok) {
				dispatch({ type: authConstants.POST_SUCCESS });
				dispatch(push("/"));
				dispatch(
					notify({
						message: "Линк за промяна на парола беше изпратен на вашият имейл.",
						status: 200
					})
				);
			} else {
				dispatch({ type: authConstants.POST_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400
					})
				);
			}
		});
	},

	resetPassword: () => async (dispatch, getState) => {
		const { form } = getState();
		const resetForm = form.ResetPasswordForm.values;
		const url = `${config.base}api/Authentication/ResetPassword`;

		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(resetForm)
		}).then(res => {
			if (res.ok) {
				dispatch({ type: authConstants.POST_SUCCESS });
				dispatch(push("/"));
				dispatch(
					notify({
						message: "Вашата парола беше променена успешно.",
						status: 200
					})
				);
			} else {
				dispatch({ type: authConstants.POST_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400
					})
				);
			}
		});
	},

	registerStudent: () => async (dispatch, getState) => {
		const { form } = getState();
		const student = form.StudentRegistrationForm.values;
		const url = `${config.base}api/Authentication/RegisterStudent`;

		student.isGraduated = student.isGraduated === "true" ? true : false;

		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(student)
		}).then(res => {
			if (res.ok) {
				dispatch({ type: authConstants.POST_SUCCESS });
				dispatch(push("/"));
				dispatch(
					notify({ message: "Регистрацията ви беше успешна.", status: 200 })
				);
			} else {
				dispatch({ type: authConstants.POST_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400
					})
				);
			}
		});
	},

	registerEmployer: () => async (dispatch, getState) => {
		const { form } = getState();
		const employer = form.EmployerRegistrationForm.values;
		const url = `${config.base}api/Authentication/RegisterEmployer`;

		return fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(employer)
		})
			.then(handleResponse, handleError)
			.then(jobOffers => {
				return jobOffers;
			})
			.then(
				jobOffers => {
					dispatch(push("/"));
					dispatch({ type: authConstants.POST_SUCCESS });
					dispatch(
						notify({ message: "Регистрацията ви беше успешна.", status: 200 })
					);
				},
				error => {
					dispatch({ type: authConstants.POST_ERROR });
					dispatch(
						notify({
							message: "Възникнала е грешка, моля опитайте отново.",
							status: 400
						})
					);
				}
			);
    },

    checkEmail: () => async (dispatch, getState) => {
        const { form } = getState();
        const email = form.EmployerRegistrationForm.values.email;
        const url = `${config.base}api/Authentication/RegisterEmployer`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(email)
        })
            .then(handleResponse, handleError)
            .then(result => {
                return result;
            })
            .then(
                result => {
                    if (result.result) {
                        dispatch(
                            notify({
                                message: "Този email е вече използван за регистрация. Моля, използвайте друг!",
                                status: 400
                            })
                        );
                        dispatch(
                            { type: authConstants.EMAIL_EXISTS, result: true }
                        );
                    }
                    else {
                        dispatch(
                            { type: authConstants.EMAIL_EXISTS, result: false }
                        );
                    }
                },
                error => {
                    dispatch({ type: authConstants.POST_ERROR });
                    dispatch(
                        notify({
                            message: "Възникнала е грешка, моля опитайте отново.",
                            status: 400
                        })
                    );
                }
            );
    },

    checkEik: () => async (dispatch, getState) => {
        const { form } = getState();
        const eik = form.EmployerRegistrationForm.values.bulstat;
        const url = `${config.base}api/Authentication/CheckEik`;

        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(eik)
        })
            .then(handleResponse, handleError)
            .then(result => {
                return result;
            })
            .then(
                result => {
                    if (result.result) {
                        dispatch(
                            notify({
                                message: "Компания с това ЕИК е вече регистрирана!",
                                status: 400
                            })
                        );
                        dispatch(
                            { type: authConstants.EIK_EXISTS, result: true }
                        );
                    }
                    else {
                        dispatch(
                            { type: authConstants.EIK_EXISTS, result: false }
                        );
                    }
                },
                error => {
                    dispatch({ type: authConstants.POST_ERROR });
                    dispatch(
                        notify({
                            message: "Възникнала е грешка, моля опитайте отново.",
                            status: 400
                        })
                    );
                }
            );
    }, 

	logout: () => async dispatch => {
		const loggedUser = getLoggedUser();
		const url = `${config.base}api/Authentication/Logout?userId=${
			loggedUser.id
		}`;

		return fetch(url, {
			method: "POST",
			headers: authHeader()
		}).then(res => {
			if (res.ok) {
				localStorage.removeItem("loggedUser");
				dispatch({ type: authConstants.LOGOUT_SUCCESS });
				dispatch(push("/"));
				dispatch(notify({ message: "Успешен изход.", status: 200 }));
			} else {
				dispatch({ type: authConstants.LOGOUT_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400
					})
				);
			}
		});
	}
};
