import React from 'react';
import { FormHelperText, Checkbox, InputLabel } from "@material-ui/core";


export const renderCheckbox = ({ input, meta, options }) => {

    const { name, onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value;

    const checkboxes = options.map(({ label, value }, index) => {

        const handleChange = (event) => {
            const arr = [...inputValue];
            if (event.target.checked) {
                arr.push(value);
            }
            else {
                arr.splice(arr.indexOf(value), 1);
            }
            return onChange(arr);
        };
        const checked = inputValue.includes(value);
        return (
            <label key={`checkbox-${index}`}>
                <Checkbox
                    color="primary"
                    type="checkbox"
                    name={`${name}[${index}]`}
                    value={value}
                    checked={checked}
                    onChange={handleChange}
                />
                <InputLabel>{label}</InputLabel>  
                <br/>
            </label>
        );
    });

    return (
        <div>
            <div>{checkboxes}</div>
            <FormHelperText error={error && touched}>{touched && error}</FormHelperText>
        </div>
    );
};