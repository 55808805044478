import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    VerticalTimeline,
    VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Storybook.css";
import Typography from "@material-ui/core/Typography";
import { Work } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { contentActionCreators } from "../actions/contentActionCreators";
import { bindActionCreators } from "redux";
import { CircularProgress } from "@material-ui/core";
import Moment from "react-moment";
import { LinkContainer } from "react-router-bootstrap";
import Button from "@material-ui/core/Button";
import { getLoggedUser } from "../helpers";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    root: {

    },
    heading: {
        color: theme.palette.primary.light,

    }
});

class Storybook extends Component {
    componentWillMount() {
        this.props.requestEvents();
    }

    render() {
        return (
            <div>
                {this.props.isLoading === false ? (
                    TimelineElements(this.props)
                ) : (
                        <CircularProgress />
                    )}
            </div>
        );
    }
}

function TimelineElements(props) {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h4" gutterBottom className={classes.heading}>
                Предстоящо
			</Typography>

            <VerticalTimeline layout="1-column" className="VerticalTimeLine">
                {props.events.length > 0 ? (
                    props.events.map(article => (
                        <VerticalTimelineElement
                            key={article.id}
                            className="vertical-timeline-element--work VerticalElement"
                            date={
                                <React.Fragment>
                                    <Moment format="DD.MM.YYYY" withTitle>
                                        {article.eventDate}
                                    </Moment>
                                    {article.eventDateEnd && 
                                        <Moment format=" - DD.MM.YYYY" withTitle>
                                            {article.eventDateEnd}
                                                </Moment>
                                    }
                                </React.Fragment>
                            }
                            iconStyle={{ background: "#760e26", color: "#fff" }}
                            icon={<Work />}>
                            <h3>
                                <Link to={`/article/${article.id}`}>{article.title}</Link>
                            </h3>
                            <p>{article.eventLocation}</p>
                        </VerticalTimelineElement>
                    ))
                ) : (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work VerticalElement"
                            iconStyle={{ background: "#760e26", color: "#fff" }}
                            icon={<Work />}>
                            Няма намерени събития.
					</VerticalTimelineElement>
                    )}
            </VerticalTimeline>
            {renderArticleBtns()}
        </div>
    );
}
function renderArticleBtns() {
    const loggedUser = getLoggedUser();
    const adminSignedIn =
        loggedUser && loggedUser.token && loggedUser.userType === "admin"
            ? true
            : false;

    return (
        <Grid container justify={"center"}>
            <Grid item xs={12} sm={8}>
                <LinkContainer style={{ marginTop: 15 }} to={`/EventsList`}>
                    <Button fullWidth variant="outlined" color="primary">
                        Вижте всички
					</Button>
                </LinkContainer>
                {adminSignedIn ? (
                    <LinkContainer style={{ marginTop: 15 }} to={`/create/article`}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            text-align="center">
                            Създаване
						</Button>
                    </LinkContainer>
                ) : null}
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(
    connect(
        state => state.content,
        dispatch => bindActionCreators(contentActionCreators, dispatch)
    )(Storybook)
);
