import React from 'react';
import { FormHelperText, TextField } from '@material-ui/core';

export const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
        <div>
            <TextField
                label={label}
                error={error && touched}
                {...input}
                {...custom}
            />
            <FormHelperText error={error && touched}>{touched && error}</FormHelperText>
        </div>
)