import React from "react";
import { FormHelperText } from "@material-ui/core";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/modern/theme";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/paste";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/wordcount";

import { Editor } from "@tinymce/tinymce-react";

export const renderTextEditor = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => {
	
	return (
		<div>
			<Editor
				label={label}
				{...input}
				{...custom}
				onBlur={event => {
					input.onChange(event.target.getContent());
				}}
				init={{
					height: 400,
                    convert_urls: false,
                    skin_url: '/tinymce/skins/lightgray',
					plugins: [
						"advlist autolink lists link image charmap print preview anchor",
						"searchreplace visualblocks code fullscreen",
						"insertdatetime media table contextmenu paste imagetools wordcount"
					],
					toolbar:
						"insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image"
				}}
			/>
			<FormHelperText error={error && touched}>
				{touched && error}
			</FormHelperText>
		</div>
	);
};
