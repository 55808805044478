import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Button,
    CircularProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { profileActionCreators } from "../../actions/profileActionCreators";
import { getLoggedUser } from "../../helpers";
import { LinkContainer } from "react-router-bootstrap";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    InsertDriveFile,
    School,
    Phone,
    ContactSupport,
    ArrowDownward,
    AlternateEmail,
    PersonAdd,
    Person,
    Info
} from "@material-ui/icons";
import JobsTable from "./JobsTable";

const styles = theme => ({
    root: {
        flexGrow:1,
        paddingTop: theme.spacing.unit * 2,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        marginBottom: 40,
        marginTop: 40
    }
});

class StudentReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {}
        };
    }

    componentWillMount() {
        const studentId = this.props.match.params.id;
        this.props.requestStudent(studentId);
    }

    render() {
        const { classes } = this.props;
        const loggedUser = getLoggedUser();
        const student = this.props.student;

        return (this.props.isLoading ? <CircularProgress /> :
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Paper>
                            <CardHeader title={`${student.firstName} ${student.middleName} ${student.lastName}`} />
                            <List component="nav">
                                <ListItem>
                                    <Avatar>
                                        <InsertDriveFile />
                                    </Avatar>
                                    <ListItemText
                                        primary="Специалност"
                                        secondary={student.speciality}
                                        secondaryTypographyProps={{ variant: "headline" }}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <Avatar>
                                        <School />
                                    </Avatar>
                                    <ListItemText
                                        primary="Университет"
                                        secondary={student.school}
                                        secondaryTypographyProps={{ variant: "headline" }}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <Avatar>
                                        <AlternateEmail />
                                    </Avatar>
                                    <ListItemText
                                        primary="E-mail"
                                        secondary={student.email}
                                        secondaryTypographyProps={{ variant: "headline" }}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <Avatar>
                                        <Phone />
                                    </Avatar>
                                    <ListItemText
                                        primary="Телефон"
                                        secondary={student.phone ? student.phone : "Не е посочен"}
                                        secondaryTypographyProps={{ variant: "headline" }}
                                    />
                                </ListItem>
                                <Divider />
                                <ListItem>
                                    <Avatar>
                                        <ContactSupport />
                                    </Avatar>
                                    <ListItemText
                                        primary="Учащ"
                                        secondary={student.isGraduated ? "Да" : "Не"}
                                        secondaryTypographyProps={{ variant: "headline" }}
                                    />
                                </ListItem>
                                <Divider />
                            </List>
                            <Card>
                                <CardActions>
                                    {student.cv ? (
                                        <Button variant="contained" color="primary" href={`/uploads/cvs/${student.id}/${student.cv}`} download>
                                            Свали автобиография  <ArrowDownward />
                                        </Button>
                                    ) : (
                                            <div>Студентът няма качена автобиография</div>
                                        )}
                                </CardActions>
                                <CardActions>
                                    <Button variant="contained" color="primary" href={`/student/profile/${student.id}`}>
                                        Редактиране на профила
                                </Button>
                                </CardActions>
                            </Card>

                            {student.jobsAppliedFor !== undefined && student.jobsAppliedFor.length > 0 &&
                                <Card>
                                    <CardHeader title="Обявите, по които е кандидатствал" />
                                    <List dense>
                                        <JobsTable offers={student.jobsAppliedFor.map(x => x.jobOffer)} />
                                    </List>
                                </Card>
                            }
                        </Paper>
                    </Grid>
                </Grid >
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(
        state => state.profiles,
        dispatch => bindActionCreators(profileActionCreators, dispatch)
    )(StudentReview)
);
