import React from "react";
import Grid from "@material-ui/core/Grid";
import NavBar from "./NavBar";
import Footer from "./Footer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withStyles } from "@material-ui/core/styles";
import Notifier from "../helpers/Notifier";

const styles = theme => ({
	mainContent: {
		[theme.breakpoints.down("xs")]: {
			padding: 0
		},
		padding: 64
	}
});

class Layout extends React.Component {
	render() {
		const props = this.props;
		const { classes } = this.props;

		return (
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<NavBar />
                    <Notifier />
				</Grid>
				<Grid item xs={12} className={classes.mainContent}>
					{props.children}
				</Grid>

				<Grid item xs={12}>
					<Footer />
				</Grid>

				<CookieConsent buttonText="Разбрах правилата и условията за ползване">
					Този уеб сайт използва cookies (бисквитки), за да предоставя
					персонализирано съдържание на потребителите. Запознайте се с{" "}
					<a
						href="https://www.unwe.bg/Uploads/Main/060d6_3.%20Privacy%20notice_Job%20Candidates_UNWE_02-03-2019.pdf"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: "#ddd" }}>
						правилата за поверителност на личните данни на УНСС
					</a>
					.
				</CookieConsent>
			</Grid>
		);
	}
}

export default withStyles(styles)(Layout);
