import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import {
	AppBar,
	Tab,
	Tabs,
	Typography,
	Paper,
	Button,
	CircularProgress
} from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { LinkContainer } from "react-router-bootstrap";
import StudentLoginForm from "./forms/StudentLoginForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";
import EmployerLoginForm from "./forms/EmployerLoginForm";
import { menuActionCreators } from "../../actions/menuActionCreators";

function TabContainer({ children, dir }) {
	return (
		<Typography component="div" dir={dir} style={{ padding: 8 * 2 }}>
			{children}
		</Typography>
	);
}

const styles = theme => ({
	root: {
		paddingTop: theme.spacing.unit * 2
	},
	heading: {
		marginLeft: theme.spacing.unit * 2,
		color: theme.palette.primary.light
	},
	loading: {
		margin: 24,
	}
});

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: props.location.hash === "#employers" ? 1 : 0
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event, selectedTab) {
		this.setState({ selectedTab });
	}

	handleChangeIndex(index) {
		this.setState({ selectedTab: index });
	}

	handleSubmit(loginType, values) {
		this.props.loginUser(loginType);
	}

	render() {
		const { classes, theme, isLoading } = this.props;
		const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

		if (loggedUser && loggedUser.token) {
			return null;
		} else {
			return (
				<Paper className={classes.root}>
					<Typography variant="h4" gutterBottom className={classes.heading}>
						Вход
					</Typography>
					{isLoading ? (
						<CircularProgress className={classes.loading} />
					) : (
						<Fragment>
							<AppBar position="static" color="default">
								<Tabs
									value={this.state.selectedTab}
									onChange={this.handleChange}
									indicatorColor="primary"
									textColor="primary"
									fullWidth>
									<Tab label="Студенти" icon={<PersonPinIcon />} />
									<Tab label="Работодатели" icon={<WorkIcon />} />
								</Tabs>
							</AppBar>

							<SwipeableViews
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={this.state.selectedTab}
								onChangeIndex={this.handleChangeIndex}>
								<TabContainer dir={theme.direction}>
									{
										<StudentLoginForm
											onSubmit={() => this.handleSubmit("student")}
										/>
									}
									<LinkContainer to="/student/registration">
										<Button color="primary" className={classes.button}>
											Регистрация
										</Button>
									</LinkContainer>
									<LinkContainer to="/forgotPassword">
										<Button color="primary" className={classes.button}>
											Забравена парола
										</Button>
									</LinkContainer>
								</TabContainer>
								<TabContainer dir={theme.direction}>
									{
										<EmployerLoginForm
											onSubmit={() => this.handleSubmit("employer")}
										/>
									}
									<LinkContainer to="/employer/registration">
										<Button color="primary" className={classes.button}>
											Регистрация
										</Button>
									</LinkContainer>
									<LinkContainer to="/forgotPassword">
										<Button color="primary" className={classes.button}>
											Забравена парола
										</Button>
									</LinkContainer>
								</TabContainer>
							</SwipeableViews>
						</Fragment>
					)}
				</Paper>
			);
		}
	}
}

export default withStyles(styles, { withTheme: true })(
	connect(
		state => {
			return { ...state.authentication, ...state.routing };
		},
		dispatch =>
			bindActionCreators(
				{ ...authenticationActionCreators, ...menuActionCreators },
				dispatch
			)
	)(Login)
);
