const validate = values => {
    const errors = {}
    const requiredFields = [
        'email',
        'password',
        'confirmPassword',
        'companyName',
        'bulstat',
        'managerName',
        'companyAddress',
        'contactPersonName',
        'contactPersonTitle',
        'contactAddress',
        'phone'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително'
        }
    });

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(values.email)) {
        errors.email = 'Невалиден E-mail адрес'
    }

    if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Паролите нe съвпадат'
    }

    if (values.hasAgreedToGdpr === undefined || (values.hasAgreedToGdpr !== undefined && !values.hasAgreedToGdpr)) {
        errors.hasAgreedToGdpr = 'Можете да продължите работата със сайта само ако сте съгласни с условията на ползване';
    }

    return errors
}

export default validate