import React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import StudentRegistration from './components/authentication/StudentRegistration';
import EmployerRegistration from './components/authentication/EmployerRegistration';
import Article from './components/items/Article';
import AddArticle from './components/items/AddArticle';
import { SignedInRoute, getLoggedUser, EmployerRoute, AdminRoute, EmployerApprovedRoute } from './helpers';
import Team from './components/content/Team';
import AdminLogin from './components/authentication/AdminLogin';
import EditArticle from './components/items/EditArticle';
import EmployerProfile from './components/users/profiles/EmployerProfile';
import { StudentRoute } from './helpers/StudentRoute';
import StudentProfile from './components/users/profiles/StudentProfile';
import EmployerOffers from './components/offers/EmployerOffers';
import AddOffer from './components/offers/AddOffer';
import JobOffer from './components/offers/JobOffer';
import EditOffer from './components/offers/EditOffer';
import JobsList from './components/offers/JobsList';
import StudentReview from './components/offers/StudentReview';
import ContestsList from './components/offers/ContestsList';
import EmployersList from './components/users/EmployersList';
import ScholarshipsList from './components/offers/ScholarshipsList';
import ForgotPassword from './components/authentication/ForgotPassword';
import ResetPassword from './components/authentication/ResetPassword';
import NewsList from './components/NewsList';
import EmployerReview from './components/offers/EmployerReview';
import EventsList from './components/EventsList';
import StudentsList from './components/users/StudentsList';
import PageNotFound from './components/PageNotFound';
import StudentOffers from './components/offers/StudentOffers';
import LogActivityList from './components/users/LogActivityList';
import Cookies from 'js-cookie';
import { config } from './helpers/config';
import { handleResponse, handleError } from './helpers';

(() => {
    //const loggedUser = getLoggedUser();

    //if(!loggedUser) {
    //	var unwesauth = Cookies.get('unwesauth');
    //       if (unwesauth !== undefined) {
    //           fetch(`/api/authentication/remote`, {
    //               method: 'POST'
    //           })
    //               .then(handleResponse, handleError)
    //               .then(loggedUser => {
    //                   if (loggedUser && loggedUser.id) {
    //                       localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
    //                       return loggedUser;
    //                   }
    //               });

    //       }
    //}

    // if (loggedUser) {

    //     let expirationDate = new Date(loggedUser.loginDate);
    //     expirationDate.setDate(expirationDate.getDate() + 7);
    //     expirationDate.setHours(0, 0, 0, 0);
    //     let currentDate = new Date();
    //     currentDate.setHours(0, 0, 0, 0);

    //     if (expirationDate.valueOf() === currentDate.valueOf()) {
    //         localStorage.removeItem('loggedUser');
    //     }
    // }
})();

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/home' component={Home} />
            <Route path='/Team' component={Team} />
            <Route path='/NewsList' component={NewsList} />
            <Route path='/EventsList' component={EventsList} />
            <SignedInRoute path='/forgotPassword' component={ForgotPassword} />
            <SignedInRoute path='/authentication/resetPassword/:secret' component={ResetPassword} exact />

            <Route exact path='/article/:id' component={Article} />
            <AdminRoute exact path='/article/edit/:id' component={EditArticle} />
            <AdminRoute exact path='/create/article' component={AddArticle} />
            <AdminRoute exact path='/employers/list/:partnersOnly?' component={EmployersList} />
            <AdminRoute exact path='/employer/review/:id' component={EmployerReview} />
            <AdminRoute exact path='/student/profile/:id' component={StudentProfile} />
            <AdminRoute exact path='/employer/profile/:id' component={EmployerProfile} />
            <AdminRoute exact path='/admin/log' component={LogActivityList} />

            <SignedInRoute path='/student/registration' component={StudentRegistration} />
            <StudentRoute exact path='/student/profile' component={StudentProfile} />
            <StudentRoute exact path='/student/offers' component={StudentOffers} />
            <EmployerApprovedRoute exact path='/student/review/:id' component={StudentReview} />
            <AdminRoute exact path='/students/list' component={StudentsList} />

            <SignedInRoute path='/employer/registration' component={EmployerRegistration} />
            <EmployerRoute exact path='/employer/profile' component={EmployerProfile} />
            <EmployerRoute exact path='/employer/offers' component={EmployerOffers} />


            <Route exact path='/job/details/:id' component={JobOffer} />
            <Route exact path='/jobs/:type' component={JobsList} />
            <Route exact path='/jobs' component={JobsList} />
            <EmployerApprovedRoute exact path='/job/add' component={AddOffer} />
            <EmployerApprovedRoute exact path='/job/edit/:id' component={EditOffer} />

            <SignedInRoute path='/admin/login' component={AdminLogin} />

            <Route component={PageNotFound} />
        </Switch>
    </Layout>
);
