import React, { Component } from 'react';
import { Grid, Button, MenuItem, InputLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/studentDetailsValidation';
import { renderTextField, renderSelectField, renderError, renderDropzoneDocField } from '../../../../helpers';

const styles = theme => ({
});

class StudentDetailsForm extends Component {

    render() {
        const { handleSubmit } = this.props;
        const cvName = this.props.cvName;

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Grid container spacing={24} style={{ marginTop: 40, marginBottom: 10 }}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="firstName"
                                component={renderTextField}
                                label="Име"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="middleName"
                                component={renderTextField}
                                label="Презиме"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="lastName"
                                component={renderTextField}
                                label="Фамилия"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                disabled
                                name="facultyNumber"
                                fullWidth
                                component={renderTextField}
                                label="Факултетен номер"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="degree"
                                component={renderSelectField}
                                label="Образователно-квалификационна степен"
                            >
                                <MenuItem value="Бакалавър">Бакалавър</MenuItem>
                                <MenuItem value="Професионален бакалавър">Професионален бакалавър</MenuItem>
                                <MenuItem value="Магистър">Магистър</MenuItem>
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="school"
                                component={renderTextField}
                                label="Учебно заведение"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="speciality"
                                component={renderTextField}
                                label="Специалност"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="phone"
                                component={renderTextField}
                                label="Телефонен номер"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label><Field color="primary" name="isGraduated" component="input" type="radio" value="true" /> Учащ</label>
                            <label><Field name="isGraduated" component="input" type="radio" value="false" /> Завършил</label>
                            <Field type="text" name="isGraduated" component={renderError} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Качете вашето CV</InputLabel>
                            <Field
                                name="cvFile"
                                cvName={cvName}
                                component={renderDropzoneDocField}
                                 />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                className="next"
                                align="right"
                            >
                                Редактирай
                            </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        )
    }
}

export default withStyles(styles)(reduxForm({
    form: 'StudentDetailsForm', // a unique identifier for this form
    destroyOnUnmount: false, // preserve form data
    validate,
    enableReinitialize: true
})(StudentDetailsForm));