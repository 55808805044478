import { push } from "react-router-redux";
import {
    authHeader,
    getConvertedBase64Images,
    handleResponse,
    handleError
} from "../helpers";
import { jobsConstants } from "../constants/jobsConstants";
import {notify} from '../helpers/notification';
import { config } from "../helpers/config";

export const jobsActionCreators = {
    requestEmployer: employerId => async dispatch => {
        dispatch({ type: jobsConstants.REQUEST_EMPLOYER_BEGIN });

        const url = `${config.base}api/Users/GetEmployer?employerId=${employerId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const employer = await response.json();

        dispatch({ type: jobsConstants.REQUEST_EMPLOYER, employer });
    },

   

    getCategories: () => async dispatch => {
        const url = `${config.base}api/Jobs/GetCategories`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const categories = await response.json();

        dispatch({ type: jobsConstants.REQUEST_CATEGORIES, categories });
    },

    addApplicant: (offerId, studentEmail) => async dispatch => {
        const url = `${
            config.base
            }api/Jobs/AddApplicant?offerId=${offerId}&email=${studentEmail}`;

        return fetch(url, {
            method: "GET",
            headers: authHeader()
        })
            .then(handleResponse, handleError)
            .then(currentOffer => {
                return currentOffer;
            })
            .then(
                currentOffer => {
                    dispatch({ type: jobsConstants.APPLY_SUCCESS, currentOffer });
                    dispatch(
                        notify({
                            message: "Вие кандидатствахте за тази обява успешно!",
                            status: 200
                        })
                    );
                },
                error => {
                    dispatch({ type: jobsConstants.APPLY_ERROR, error });
                    dispatch(
                        notify({
                            message: "Възникнала е грешка, моля опитайте отново.",
                            status: 400
                        })
                    );
                }
            );
    },

    submitJobOffer: (imagesNames) => async (dispatch, getState) => {
        const { form } = getState();
        const jobOffer = form.AddJobForm.values;
        const url = `${config.base}api/Jobs/CreateJobOffer`;

        if (jobOffer.imagesFiles && jobOffer.imagesFiles.length > 0) {
            let images = jobOffer.imagesFiles;
            let base64Images = await getConvertedBase64Images(images);

            //	jobOffer.imagesFiles = base64Images.convertedImages;
            jobOffer.imagesNames = imagesNames;
        }

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(jobOffer)
        })
            .then(res => {
                if (res.ok) {
                    dispatch({ type: jobsConstants.POST_SUCCESS });
                    dispatch(push("/employer/offers"));
                    dispatch(
                        notify({ message: "Обявата беше качена успешно!", status: 200 })
                    );
                } else {
                    dispatch({ type: jobsConstants.POST_ERROR });
                    dispatch(
                        notify({
                            message: "Възникнала е грешка, моля опитайте отново.",
                            status: 400
                        })
                    );
                }
            })
            .catch(err => {
                console.log(err);
            });
    },

    uploadImages: filesToUpload => async dispatch => {
        dispatch({ type: jobsConstants.UPLOAD_FILE_BEGIN });

        const url = `${config.base}api/Jobs/UploadImages`;

        if (filesToUpload && filesToUpload.length > 0) {
            let images = filesToUpload;
            let base64Images = await getConvertedBase64Images(images);
            let imagesFiles = base64Images.convertedImages;
            let imagesNames = base64Images.imagesNames;
            const response = await fetch(url, {
                headers: authHeader(),
                method: "POST",
                body: JSON.stringify({ files: imagesFiles, names: imagesNames })
            });
            const result = await response.json();
            const status = response.status;
            if (status === 200) {
                dispatch({ type: jobsConstants.UPLOAD_FILE_SUCCESS, result: result });
            } else {
                dispatch({ type: jobsConstants.UPLOAD_FILE_ERROR });
                dispatch(
                    notify({
                        message: "Възникнала е грешка при качването на файл, моля опитайте отново или се свържете с администратор.",
                        status: 400
                    })
                );
            }
        }
    },

    requestUsersJobOffers: userId => async dispatch => {
        const url = `${config.base}api/Jobs/GetUserJobOffers?userId=${userId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const jobOffers = await response.json();

        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
    },

    requestJobsOffers: (type) => async dispatch => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const url = `${config.base}api/Jobs/GetJobOffers?type=${type}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const jobOffers = await response.json();

        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
    },

    filterJobOffers: () => async (dispatch, getState) => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const { form } = getState();
        const categories = form.FilterCategoriesForm.values;
        const url = `${config.base}api/Jobs/GetFilteredJobsOffers`;

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(categories)
        })
            .then(handleResponse, handleError)
            .then(jobOffers => {
                return jobOffers;
            })
            .then(
                jobOffers => {
                    dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
                },
                error => {
                    dispatch({ type: jobsConstants.APPLY_ERROR, error });
                }
            );
    },

    requestContestsOffers: () => async dispatch => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const url = `${config.base}api/Jobs/GetContestsOffers`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const jobOffers = await response.json();

        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
    },

    filterContestsOffers: () => async (dispatch, getState) => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const { form } = getState();
        const categories = form.FilterCategoriesForm.values;
        const url = `${config.base}api/Jobs/GetFilteredContestsOffers`;

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(categories)
        })
            .then(handleResponse, handleError)
            .then(jobOffers => {
                return jobOffers;
            })
            .then(
                jobOffers => {
                    dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
                },
                error => {
                    dispatch({ type: jobsConstants.APPLY_ERROR, error });
                }
            );
    },

    requestScholarshipOffers: () => async dispatch => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const url = `${config.base}api/Jobs/GetScholarshipOffers`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const jobOffers = await response.json();

        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
    },

    filterScholarshipOffers: () => async (dispatch, getState) => {
        dispatch({ type: jobsConstants.REQUEST_JOBOFFERS_BEGIN });

        const { form } = getState();
        const categories = form.FilterCategoriesForm.values;
        const url = `${config.base}api/Jobs/GetFilteredScholarshipOffers`;

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(categories)
        })
            .then(handleResponse, handleError)
            .then(jobOffers => {
                return jobOffers;
            })
            .then(
                jobOffers => {
                    dispatch({ type: jobsConstants.REQUEST_JOBOFFERS, jobOffers });
                },
                error => {
                    dispatch({ type: jobsConstants.APPLY_ERROR, error });
                }
            );
    },

    getOffer: offerId => async dispatch => {
        dispatch({ type: jobsConstants.REQUEST_OFFERDETAILS_BEGIN });

        const url = `${config.base}api/Jobs/GetOffer?offerId=${offerId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const currentOffer = await response.json();

        if (currentOffer.expirationDate) {
            currentOffer.expirationDate = currentOffer.expirationDate.split("T")[0];
        }

        if (currentOffer.createdOn) {
            currentOffer.createdOn = currentOffer.createdOn.split("T")[0];
        }

        dispatch({ type: jobsConstants.REQUEST_OFFERDETIALS, currentOffer });
    },

    editOffer: (imagesNames) => async (dispatch, getState) => {
        const { form } = getState();
        const jobOffer = form.AddJobForm.values;
        const url = `${config.base}api/Jobs/EditJobOffer`;

        let images = jobOffer.imagesFiles;

        if (images != null && images.length > 0 && typeof images[0] !== "string") {
            jobOffer.imagesNames = imagesNames;
        }

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(jobOffer)
        })
            .then(res => {
                if (res.ok) {
                    dispatch({ type: jobsConstants.POST_SUCCESS });
                    dispatch(push("/employer/offers"));
                    dispatch(
                        notify({
                            message: "Обявата беше редактирана успешно!",
                            status: 200
                        })
                    );
                } else {
                    dispatch({ type: jobsConstants.POST_ERROR });
                    dispatch(
                        notify({
                            message: "Възникнала е грешка, моля опитайте отново.",
                            status: 400
                        })
                    );
                }
            })
            .catch(err => {
                console.log(err);
            });
    },

    deleteOffer: offerId => async dispatch => {
        const url = `${config.base}api/Jobs/DeleteJobOffer?offerId=${offerId}`;

        return fetch(url, {
            method: "POST",
            headers: authHeader()
        }).then(res => {
            if (res.ok) {
                dispatch({ type: jobsConstants.POST_SUCCESS });
                dispatch(push("/employer/offers"));
                dispatch(
                    notify({ message: "Обявата беше изтрита успешно!", status: 200 })
                );
            } else {
                dispatch({ type: jobsConstants.POST_ERROR });
                dispatch(
                    notify({
                        message: "Възникнала е грешка, моля опитайте отново.",
                        status: 400
                    })
                );
            }
        });
    },

    searchJobOffers: (searchTerm, type) => async dispatch => {
        dispatch({
            type: jobsConstants.REQUEST_JOBOFFERS_BEGIN
        });
        const url = `${config.base}api/Jobs/SearchJobOffers?searchTerm=${searchTerm}&type=${type}`;
        const response = await fetch(url);
        const jobOffers = await response.json();

        dispatch({
            type: jobsConstants.REQUEST_JOBOFFERS,
            jobOffers
        });
    },

    removeSearchJobOffer: (typeId) => async dispatch => {
        dispatch({
            type: jobsConstants.REQUEST_JOBOFFERS_BEGIN
        });
        const jobsUrl = `${config.base}api/Jobs/GetJobOffers?type=${typeId}`;
        const jobsResponse = await fetch(jobsUrl);
        const jobOffers = await jobsResponse.json();
        console.log('jobs');
        dispatch({
            type: jobsConstants.REQUEST_JOBOFFERS,
            jobOffers
        });
    },

    getOffersCount: () => async (dispatch) => {
        const url = `${config.base}api/Jobs/CountOffers`;

        return fetch(url, {
            method: 'GET',
            headers: authHeader()
        }).then(handleResponse, handleError)
            .then(offers => {
                dispatch({ type: jobsConstants.STATISTICS_OFFERS, countOffers: offers.countOffers });
            });
    }
};
