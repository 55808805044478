import React, { Component } from 'react';
import { Grid, Typography, Button, RadioGroup, Radio, FormControlLabel, FormLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/employerRegValidate';
import asyncValidate from './validations/asyncValidate';
import { renderTextField, renderRadioBtn } from '../../../helpers';

const styles = theme => ({
});

class EmployerCompanyDetails extends Component {

    render() {
        const { handleSubmit, previousPage, restrictFinishRegistration } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Typography variant="h6" gutterBottom align="center">
                        Данни за организацията
                </Typography>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="companyName"
                                fullWidth
                                component={renderTextField}
                                label="Име на компанията"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="bulstat"
                                fullWidth
                                component={renderTextField}
                                label="ЕИК"
                            />
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Field name="isHrCompany" component={renderRadioBtn}>
                                <FormLabel component="legend">Компания за подбор на кадри ли сте?</FormLabel>
                                <FormControlLabel
                                    value="true"
                                    control={<Radio color="primary" />}
                                    label="Да"
                                    align="left"
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio color="primary" />}
                                    label="Не"
                                    align="left"
                                />
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="managerName"
                                fullWidth
                                component={renderTextField}
                                label="Лице представляващо компанията"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="viceManagerName"
                                fullWidth
                                component={renderTextField}
                                label="Второ лице, представляващо компанията"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="companyAddress"
                                fullWidth
                                component={renderTextField}
                                label="Седалище и адрес на управление"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="website"
                                fullWidth
                                component={renderTextField}
                                label="Интернет страница"
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginRight: 10 }}
                                onClick={previousPage}
                                className="next"
                                align="inline"
                            >
                                Назад
                        </Button>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={restrictFinishRegistration}
                                color="primary"
                                className="next"
                                align="inline"
                            >
                                Напред
                        </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
      )
    }
}

export default withStyles(styles)(reduxForm({
    form: 'EmployerRegistrationForm', // a unique identifier for this form
    destroyOnUnmount: false, // preserve form data
    validate,
    asyncValidate
})(EmployerCompanyDetails));