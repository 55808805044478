import React, { Component } from "react";
import { MenuItem, Grid, Button, InputLabel, Snackbar, SnackbarContent } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import validate from "./validations/validateJobOffer";
import {
    renderTextField,
    renderSelectField,
    renderTextEditor,
    renderMultiDropzoneImageField,
    renderCheckbox
} from "../../../helpers";
import InfoIcon from '@material-ui/icons/Info';
import blueGrey from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit
    },
    dateField: {
        width: 200
    },
    snackbar: {
        backgroundColor: blueGrey[50],
        color: blueGrey[900],
        maxWidth: '100%'
    },
    snackbar_date: {
        backgroundColor: blueGrey[50],
        color: blueGrey[900],
        maxWidth: '100%',
        boxShadow: 'none',
    },
});

class JobForm extends Component {
    constructor(props) {
        super(props);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.state = { type: null };
    }

    onTypeChange = value => {
        console.log(value);
        this.setState({ type: value });
    };

    render() {
        const {
            classes,
            handleSubmit,
            onDrop,
            categories,
            userType,
            imagesNames,
            userId
        } = this.props;
        const { typeId } = this.props;
        const path = `/uploads/jobsOffersImages/`;

        let categoriesConverted = [];

        for (var i in categories) {
            let currCategory = { label: categories[i].name, value: categories[i].id };
            categoriesConverted.push(currCategory);
        }

        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="typeId"
                                component={renderSelectField}
                                label="Тип"
                                margin="dense">
                                <MenuItem value={1}>Работа</MenuItem>
                                <MenuItem value={2}>Стаж</MenuItem>
                                {userType === 'admin' ? (
                                    <MenuItem value={3}>Конкурс</MenuItem>
                                ) : null}
                                {userType === 'admin' ? (
                                    <MenuItem value={4}>Стипендия</MenuItem>
                                ) : null}
                            </Field>
                            <Field
                                name="title"
                                component={renderTextField}
                                style={{ marginLeft: 7 }}
                                margin="dense"
                                fullWidth
                                label="Заглавие"
                            />
                            <SnackbarContent gutterBottom
                                aria-describedby="client-snackbar" className={classes.snackbar_date}
                                        message={
                                            <span id="client-snackbar">
                                            Ако използвате Internet Explorer: форматът на датата: гггг-мм-дд (пример: 2019-08-31) .
                                        </span>
                                        }
                                    />
                            <Field
                                name="expirationDate"
                                component={renderTextField}
                                type="date"
                                className={classes.dateField}
                                label="Обявата изтича на"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <br />
                            <InputLabel>Съдържание</InputLabel>
                            <Field
                                name="content"
                                component={renderTextEditor}
                                style={{ marginLeft: 7 }}
                                margin="dense"
                                fullWidth
                                label="Съдържание"
                            />
                            <br />
                            {typeId === 1 || typeId === 2 ? (
                                <div>
                                    <h4>Категории</h4>
                                    <Field
                                        name="jobCategories"
                                        component={renderCheckbox}
                                        margin="dense"
                                        options={categoriesConverted}
                                    />
                                </div>
                            ) : null}
                            <Grid>
                                    <SnackbarContent gutterBottom
                                        aria-describedby="client-snackbar" className={classes.snackbar}
                                        message={
                                            <span id="client-snackbar">
                                            Междууниверситетският център за развитие на кариерата (МЦРК) на УНСС не носи отговорност за верността и актуалността на публикуваните обяви.
                                                    Цялата отговорност за верността и актуалността на публикуваните обяви  се носи от лицето/компанията, осъществили публикацията. С факта на публикуването публикуващият декларира, че в обявите не се съдържа подвеждаща или невярна информация.
                                                Всяка обява може да бъде коригирана, изтрита или деактивирана. Корекцията на информацията в обява или нейното изтриване се извършва незабавно (on-line).
                                        </span>
                                        }
                                    />

                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    className={classes.menuButton}>
                                    Публикувай
								</Button>
                            </Grid>
                        </Grid>
                        <Grid item md={5}>
                            <InputLabel>Снимки към обявата</InputLabel>
                            <Field
                                name="imagesFiles"
                                imagesNames={imagesNames}
                                folderName="jobsOffersImages"
                                userId={userId}
                                component={renderMultiDropzoneImageField}
                                onChange={onDrop}
                            />
                            {imagesNames && Array.isArray(imagesNames) && (
                                <div>
                                    {imagesNames.map((file, i) => (
                                        <div key={i}>
                                            <img
                                                alt="Снимка към статията"
                                                style={{ width: 300 }}
                                                id={"article-img-" + i}
                                                src={`${path}/${file ? file : "ERROR"}`}
                                            />
                                            <br />
                                            <input
                                                type="text"
                                                disabled
                                                value={`${path}/${file ? file : "ERROR"}`}
                                                style={{ padding: 8, width: 300 }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }
}

let JobFormRedux = reduxForm({
    form: "AddJobForm", // a unique identifier for this form
    enableReinitialize: true,
    initialValues: { enabled: true },
    validate
})(JobForm);

const selector = formValueSelector("AddJobForm"); // <-- same as form name
JobFormRedux = connect(state => {
    // can select values individually
    const typeId = selector(state, "typeId");

    return {
        typeId
    };
})(JobFormRedux);

export default withStyles(styles)(JobFormRedux);
