import React, { Component } from "react";
import { MenuItem, Grid, Button, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import validate from "./validateArticle";
import {
	renderTextField,
	renderSelectField,
	renderTextEditor,
	renderMultiDropzoneImageField
} from "../../../helpers";

const styles = theme => ({
	margin: {
		margin: theme.spacing.unit
	},
	dateField: {
		width: 200
	}
});

class ArticleForm extends Component {
	render() {
		const {
			classes,
			handleSubmit,
			onDrop,
			userId,
			pristine,
            submitting,
            article
		} = this.props;
        const path = `/uploads/articlesImages/`;

		return (
			<div>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={16}>
						<Grid item xs={12} md={6}>
							<Field
								name="articleTypeId"
								component={renderSelectField}
								label="Тип"
								margin="dense">
								<MenuItem value={1}>Новина</MenuItem>
								<MenuItem value={2}>Събитие</MenuItem>
								<MenuItem value={3}>Страница</MenuItem>
							</Field>
							<Field
								name="eventDate"
								component={renderTextField}
								type="date"
								className={classes.dateField}
								label="Дата на събитието"
								InputLabelProps={{
									shrink: true
								}}
							/>
                            <Field
                                name="eventDateEnd"
                                component={renderTextField}
                                type="date"
                                className={classes.dateField}
                                label="Крайна дата"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
							<Field
								name="eventLocation"
								component={renderTextField}
								style={{ marginLeft: 7 }}
								margin="dense"
								className={classes.eventLocation}
								label="Място на събитието"
							/>
							<Field
								name="title"
								component={renderTextField}
								style={{ marginLeft: 7 }}
								margin="dense"
								fullWidth
								label="Заглавие"
							/>
							<Field
								name="coverImg"
								component={renderTextField}
								style={{ marginLeft: 7 }}
								margin="dense"
								fullWidth
								label="Снимка към публикацията"
							/>
							<Field
								name="content"
								component={renderTextEditor}
								style={{ marginLeft: 7 }}
								margin="dense"
								fullWidth
								label="Съдържание"
							/>
							<br />
							<Grid align="right">
								<Button
									variant="contained"
									type="submit"
									color="primary"
									className={classes.menuButton}>
									Публикувай
								</Button>
							</Grid>
						</Grid>
						<Grid item md={5}>
							<InputLabel>Снимки към статията</InputLabel>
							<Field
								name="imagesFiles"
								component={renderMultiDropzoneImageField}
								userId={userId}
								folderName="articlesImages"
								onChange={onDrop}
							/>
							{article.imagesNames && Array.isArray(article.imagesNames) && (
								<div>
									{article.imagesNames.map((file, i) => (
										<div key={i}>
											<img
												alt="Снимка към статията"
												style={{ width: 300 }}
												id={"article-img-" + i}
												src={`${path}/${file ? file : "ERROR"}`}
											/>
											<br />
											<input
												type="text"
												disabled
												value={`${path}/${file ? file : "ERROR"}`}
												style={{ padding: 8, width: 300 }}
											/>
										</div>
									))}
								</div>
							)}
						</Grid>
					</Grid>
				</form>
			</div>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "ArticleForm", // a unique identifier for this form
		validate
	})(ArticleForm)
);
