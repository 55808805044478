import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/employerRegValidate";
import asyncValidate from "./validations/asyncValidate";
import { renderTextField, renderSingleCheckboxField } from "../../../helpers";

const styles = theme => ({
    link: {
        color: theme.palette.primary.main
    }
});

class EmployerBaseForm extends Component {
    render() {
        const { handleSubmit, classes, restrictFinishRegistration } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Typography variant="h6" gutterBottom align="center">
                        Потребителски профил - работодател
					</Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Field
                                name="email"
                                fullWidth
                                component={renderTextField}
                                label="E-mail"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="password"
                                type="password"
                                component={renderTextField}
                                label="Парола"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                fullWidth
                                name="confirmPassword"
                                type="password"
                                component={renderTextField}
                                label="Повторете парола"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="hasAgreedToGdpr"
                                type="checkbox"
                                component={renderSingleCheckboxField}
                                label={
                                    <a
                                        href="https://www.unwe.bg/Uploads/Main/060d6_3.%20Privacy%20notice_Job%20Candidates_UNWE_02-03-2019.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.link}>
                                        Запознат/а съм и съм съгласен/съгласна с условията и
										правилата за защита на личните данни на УНСС
									</a>
                                }
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={restrictFinishRegistration}
                                color="primary"
                                className="next">
                                Напред &gt;
							</Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        );
    }
}

export default withStyles(styles)(
    reduxForm({
        form: "EmployerRegistrationForm", // a unique identifier for this form
        destroyOnUnmount: false, // preserve form data
        initialValues: {
            isHrCompany: "false"
        },
        validate,
        asyncValidate
    })(EmployerBaseForm)
);
