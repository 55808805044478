import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Grid,
    Paper,
    withStyles,
    Button,
    List,
    ListItem,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    CircularProgress,
    LinearProgress,
    TablePagination,
    FormControlLabel,
    Switch
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import Moment from "react-moment";
import { profileActionCreators } from "../../actions/profileActionCreators";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Warning, Label, WarningOutlined } from "@material-ui/icons";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        overflow: "hidden",
        textAlign: "justify"
    },
    coverImage: {
        width: "100%"
    },
    socialShare: {
        color: theme.palette.primary.main
    }
});

class EmployersList extends Component {
    componentWillMount() {
        this.props.requestEmployers(this.props.match.params.partnersOnly || 0, this.props.page, this.props.count, "", this.props.match.params.partnersOnly == 1 ? 7 : 0, this.props.match.params.partnersOnly == 1 ? "asc" : "desc");
    }

    componentWillReceiveProps(next) {
        if (next.match.params.partnersOnly === this.props.match.params.partnersOnly) return;
        this.props.requestEmployers(next.match.params.partnersOnly || 0, this.props.page, this.props.count, "", next.match.params.partnersOnly == 1 ? 7 : 0, next.match.params.partnersOnly == 1 ? "asc" : "desc");
    }

    handleApprove(employerId) {
        this.props.approveEmployer(employerId, this.props.requestApprovedEmployers);
    }

    pageChange = (page, count) => {
        if (this.props.page === page || page === 0) return;
        this.props.requestEmployers(this.props.match.params.partnersOnly || 0, page, count, "", this.props.match.params.partnersOnly == 1 ? 7 : 0, this.props.match.params.partnersOnly == 1 ? "asc" : "desc");
    };

    search = searchText => {
        this.props.requestEmployers(this.props.match.params.partnersOnly || 0, this.props.page, this.props.count, searchText, this.props.match.params.partnersOnly == 1 ? 7 : 0, this.props.match.params.partnersOnly == 1 ? "asc" : "desc");
    };

    sort = (column, direction) => {
        this.props.requestEmployers(
            this.props.match.params.partnersOnly || 0,
            this.props.page,
            this.props.count,
            "",
            column,
            direction
        );
    };

    render() {
        const { classes } = this.props;
        const { page, count, employers, countEmployers } = this.props;
        let columns = [
            {
                name: "ID",
                options: { display: false }
            },
            " ",
            "Организация",
            "БУЛСТАТ",
            "Регистриран на",
            "Управител",
            "Лице за кореспондеция",
            {
                name: "Одобрен",
                options: {
                    filter: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <FormControlLabel
                                label={value ? "Да" : "Не"}
                                value={value ? "Да" : "Не"}
                                control={
                                    <Switch
                                        color="primary"
                                        checked={value}
                                        value={value ? "Да" : "Не"}
                                    />
                                }
                                onChange={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }}
                            />
                        );
                    }
                }
            }
        ];
        if (this.props.match.params.partnersOnly == 1) {
            columns = [
                {
                    name: "ID",
                    options: { display: false }
                },
                " ",
                "Организация",
                "БУЛСТАТ",
                "Управител",
                "Лице за кореспондеция",
                "Координатор",
                {
                    name: "Дата на приключване",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div>
                                    {moment(value, "DD.MM.YYYY").diff(moment()) < 15552000000 ? <WarningOutlined color="error" /> : null} 
                                    {moment(value, "DD.MM.YYYY").format("DD.MM.YYYY")}
                                </div>
                            );
                        }
                    }
                },
                {
                    name: "Одобрен",
                    options: {
                        filter: true,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <FormControlLabel
                                    label={value ? "Да" : "Не"}
                                    value={value ? "Да" : "Не"}
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={value}
                                            value={value ? "Да" : "Не"}
                                        />
                                    }
                                    onChange={event => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    }}
                                />
                            );
                        }
                    }
                }
            ];
        }

        const options = {
            filter: false,
            responsive: "stacked",
            serverSide: true,
            rowsPerPage: 100,
            page: page,
            count: countEmployers,
            customFooter: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage
            ) => (
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        backIconButtonProps={{
                            "aria-label": "Предишна страница"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Следваща страница"
                        }}
                        onChangePage={(e, pageNumber) => changePage(pageNumber + 1)}
                    />
                ),
            onTableChange: (action, tableState) => {
                console.log(action, tableState);

                switch (action) {
                    case "changePage":
                        this.pageChange(tableState.page, tableState.count);
                        break;
                    case "search":
                        if (
                            tableState.searchText == null ||
                            tableState.searchText.length < 3
                        )
                            return;
                        this.search(tableState.searchText);
                        break;
                    case "sort":
                        this.sort(
                            tableState.activeColumn,
                            tableState.columns[tableState.activeColumn].sortDirection
                        );
                        break;
                }
            },

            onRowClick: (rowData, rowMeta) => {
                this.props.redirectToEmployerReview(rowData[0]);
            }
        };

        return (
            <div className={classes.root}>
                <Grid container spacing={24}>
                    {this.props.isLoading && (
                        <Grid item xs={12}>
                            <LinearProgress />
                        </Grid>
                    )}
                    {employers !== undefined &&
                        renderEmployerList(
                            "Списък " + (this.props.match.params.partnersOnly == 1 ? " партньори със споразумение" : " работодатели"),
                            employers.map((x, i) => this.props.match.params.partnersOnly != 1 ? [
                                x.userId,
                                options.rowsPerPage * (this.props.page - 1) + i + 1,
                                x.companyName,
                                x.bulstat,
                                x.userCreatedOn
                                    ? moment(x.userCreatedOn).format("DD.MM.YYYY HH:mm")
                                    : "",
                                x.managerName,
                                x.contactPersonName,
                                x.isApproved
                            ] : [
                                    x.userId,
                                    options.rowsPerPage * (this.props.page - 1) + i + 1,
                                    x.companyName,
                                    x.bulstat,
                                    x.managerName,
                                    x.contactPersonName,
                                    x.agreementCoordinatorName,
                                    moment(x.agreementEndDate).format("DD.MM.YYYY"),
                                    x.isApproved
                                ]),
                            true,
                            columns,
                            options,
                            this.props.isLoading
                        )}
                </Grid>
            </div>
        );
    }
}

function renderEmployerList(
    title,
    employers,
    canApproved,
    columns,
    options,
    isLoading
) {
    if (employers === undefined) {
        return;
    }

    return (
        <Grid item xs={12} style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}>
            <MUIDataTable
                title={title}
                data={employers}
                columns={columns}
                options={options}
            />
        </Grid>
    );
}

export default withStyles(styles)(
    connect(
        state => state.profiles,
        dispatch => bindActionCreators(profileActionCreators, dispatch)
    )(EmployersList)
);
