import React from 'react';
import { connect } from 'react-redux';
import Login from './authentication/Login';
import News from './News';
import Grid from '@material-ui/core/Grid';
import Statistic from './Statistic';
import Storybook from './Storybook';

const Home = props => (
    <Grid container spacing={16}>
        <Grid item xs={12} sm={4}>
            <News />
        </Grid>
        <Grid item xs={12} sm={5}>
            <Storybook />
        </Grid>
        <Grid item xs={12} sm={3}>
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Login />
                </Grid>
                <Grid item xs={12}>
                    <a href="https://careerdays.bg/bg/economy-careerdays" target="_blank"><img src="https://www.unwe.bg/Uploads/Main/17b72_thumbnail_CareerDaysUNWE.png" alt="Career Days 2024" width="100%" /></a>
                    <a href="https://www.bta.bg/bg/bta-academy/internship/" target="_blank"><img src="/img/bta.png" alt="Школа БТА" width="100%" /></a>
                </Grid>
                <Grid item xs={12}>
                    <Statistic />
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default connect()(Home);
