import React from 'react';
import { RadioGroup, FormHelperText } from "@material-ui/core";


export const renderRadioBtn = ({ input, meta: { touched, error }, children, ...rest }) => (
    <div>
        <RadioGroup
            {...input}
            {...rest}
            children={children}
            valueSelected={input.value}
            onChange={(event) => input.onChange(event.target.value)}
        />
        <FormHelperText error={touched && error}>{touched && error}</FormHelperText>
    </div>
)