import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Button,
    Grid,
    Paper,
    Input,
    Toolbar,
    Typography
} from "@material-ui/core";
import toRenderProps from "recompose/toRenderProps";
import withState from "recompose/withState";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import common from "@material-ui/core/colors/common";
import ScrollTrigger from "react-scroll-trigger";
import { bindActionCreators } from "redux";
import { menuActionCreators } from "../actions/menuActionCreators";
import { authenticationActionCreators } from "../actions/authentication/authenticationActionCreators";
import { getLoggedUser } from "../helpers";
import { ExitToApp, Business, People, Domain, Ballot, Assignment, AssignmentInd, BusinessCenter } from '@material-ui/icons';
import { jobsActionCreators } from "../actions/jobsActionCreators";
import { debounce } from "lodash";

const styles = theme => ({
    root: {
        flexGrow: 2
    },
    grow: {
        flexGrow: 2
    },
    toolbar: {
        [theme.breakpoints.down("sm")]: {
            display: "block",
            paddingTop: theme.spacing.unit * 2,
            paddingBottom: theme.spacing.unit * 2
        }
    },
    menuButton: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    mainFeaturedPostContent: {
        padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 6}px`,
        [theme.breakpoints.up("md")]: {
            paddingRight: 0
        },
        color: common.white,
        fontWeight: 700,
        textShadow: "1px 1px 1px #000",
        [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing.unit * 11}px ${theme.spacing.unit * 6}px`
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 25
        }
    },
    mainFeaturedPost: {
        backgroundImage: 'url("/img/Capture.JPG")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed"
    },

    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing.unit,
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 120,
            "&:focus": {
                width: 200
            }
        }
    },
    appbarSticky: {
        [theme.breakpoints.up("sm")]: {
            position: "fixed",
            top: 0,
            zIndex: theme.zIndex.drawer + 1
        }
    },
    userMenu: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            display: 'block',
            '& a': {
                display: 'block',
                fontWeight: 'bold'
            },
            '& button': {
                display: 'block'
            }
        }
    },
});

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

class ButtonAppBar extends Component {
    constructor(props) {
        super(props);
        const loc = this.props.location.pathname.split('/');
        this.state = {
            fixed: false,
            locationPartOne: loc[1],
            locationPartTwo: loc[2]
        };
        this.getTypeId = this.getTypeId.bind(this);
        this.handleSearch = debounce(this.handleSearch.bind(this), 750);
        this.handleSearchInput = this.handleSearchInput.bind(this);
    }

    componentDidMount() {
        this.props.requestMenuElements("main");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const loc = this.props.location.pathname.split('/');
            this.setState({
                locationPartOne: loc[1],
                locationPartTwo: loc[2]
            });
        }
    }

    onEnterViewport = () => {
        this.setState({
            fixed: false
        });
    };

    onExitViewport = () => {
        this.setState({
            fixed: true
        });
    };

    handleLogout = e => {
        this.props.logout();
    };

    handleSearchInput = e => {
        let searchTerm = e.target.value;
        this.handleSearch(searchTerm);
    };

    handleSearch = searchTerm => {
        const { locationPartOne } = this.state;

        if (searchTerm.length > 0) {
            (locationPartOne === undefined || locationPartOne === '' || locationPartOne.toLowerCase() === 'news')
                && this.props.searchNews(searchTerm);
            (locationPartOne === undefined || locationPartOne === '' || locationPartOne.toLowerCase() === 'events')
                && this.props.searchEvents(searchTerm);
            (locationPartOne.toLowerCase() === 'jobs')
                && this.props.searchJobOffers(searchTerm, this.getTypeId());
        } else {
            (locationPartOne === undefined || locationPartOne === '' || locationPartOne.toLowerCase() === 'news' || locationPartOne.toLowerCase() === 'events')
                && this.props.removeSearch();
            (locationPartOne.toLowerCase() === 'jobs')
                && this.props.removeSearchJobOffer(this.getTypeId());
        }
    };

    getTypeId() {
        const types = { "": 1, contests: 3, scholarships: 4 };
        const { locationPartTwo } = this.state;
        return types[locationPartTwo] || 1;
    }

    renderUserMenu = (loggedUser) => {
        const { classes } = this.props;

        return (
            <div className={classes.userMenu}>
                {loggedUser &&
                    loggedUser.token &&
                    loggedUser.userType === "employer" ? (
                        <div>
                            <LinkContainer to="/employer/offers">
                                <Button color="inherit"><Ballot /> Нашите обяви</Button>
                            </LinkContainer>
                            <LinkContainer to="/employer/profile">
                                <Button color="inherit"><Domain /> Профил</Button>
                            </LinkContainer>
                        </div>
                    ) : null}
                {loggedUser && loggedUser.token && loggedUser.userType === "student" ? (
                    <div>
                        <LinkContainer to="/student/profile">
                            <Button color="inherit"><AssignmentInd />Моят профил</Button>
                        </LinkContainer>
                        <LinkContainer to="/student/offers">
                            <Button color="inherit"><Assignment />Моите обяви</Button>
                        </LinkContainer>
                    </div>
                ) : null}
                {loggedUser && loggedUser.token && loggedUser.userType === "admin" ? (
                    <div>
                        <LinkContainer to="/admin/log">
                            <Button color="inherit"><Assignment />Дейности</Button>
                        </LinkContainer>
                        <LinkContainer to="/employers/list">
                            <Button color="inherit"><Business />Работодатели</Button>
                        </LinkContainer>
                        <LinkContainer to="/employers/list/1">
                            <Button color="inherit"><BusinessCenter />Споразумения</Button>
                        </LinkContainer>
                        <LinkContainer to="/students/list">
                            <Button color="inherit"><People />Студенти</Button>
                        </LinkContainer>
                    </div>
                ) : null}
                <Button color="inherit" onClick={this.handleLogout}>
                    <ExitToApp />
                    Изход
				</Button>
            </div>
        );
    };

    render() {
        const props = this.props;
        const { classes } = props;
        const { fixed } = this.state;
        const loggedUser = getLoggedUser();

        return (
            <ScrollTrigger
                onEnter={this.onEnterViewport}
                onExit={this.onExitViewport}>
                <div className={classes.root}>
                    <a className="navbar-brand" href="http://www.unwe.bg/bg/">
                        <picture>
                            <source
                                srcSet="https://common2.unwe.bg//wwwroot/images/logo-long-bg.png"
                                media="(min-width: 1240px)"
                            />
                            <img
                                srcSet="https://common2.unwe.bg//wwwroot/images/logo-short-bg.png"
                                alt="UNWE"
                            />
                        </picture>
                    </a>
                    <Paper className={classes.mainFeaturedPost}>
                        <Grid container>
                            <Grid>
                                <Typography
                                    variant="display2"
                                    className={classes.mainFeaturedPostContent}>
                                    <Link to={"/"}>
                                        Междууниверситетски център за развитие на кариерата
									</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <AppBar
                        position="static"
                        className={fixed ? classes.appbarSticky : ""}>
                        <Toolbar className={classes.toolbar}>
                            <WithState>
                                {({ anchorEl, updateAnchorEl }) => {
                                    const open = Boolean(anchorEl);
                                    const handleClose = () => {
                                        updateAnchorEl(null);
                                    };

                                    return (
                                        <React.Fragment>
                                            {props.elements.map(element => (
                                                <LinkContainer
                                                    key={element.id}
                                                    to={
                                                        element.articleId != null
                                                            ? `/article/${element.articleId}`
                                                            : element.link
                                                    }>
                                                    <Button
                                                        color="inherit"
                                                        className={classes.menuButton}>
                                                        {element.title}
                                                    </Button>
                                                </LinkContainer>
                                            ))}
                                        </React.Fragment>
                                    );
                                }}
                            </WithState>
                            <div className={classes.grow} />
                            {this.props.showLogoutBtn || loggedUser ? this.renderUserMenu(loggedUser) : null}
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <Input
                                    placeholder="Търсене…"
                                    disableUnderline
                                    onChange={this.handleSearchInput}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                />
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
            </ScrollTrigger>
        );
    }
}

ButtonAppBar.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
    connect(
        state => {
            return { ...state.menu, ...state.routing };
        },
        dispatch =>
            bindActionCreators(
                { ...menuActionCreators, ...authenticationActionCreators, ...jobsActionCreators },
                dispatch
            )
    )(ButtonAppBar)
);
