export const authConstants = {
    CREATE_USER_REG_FORM: 'CREATE_USER_REG_FORM',
    SET_REG_USER_BASE_FORM: 'SET_REG_USER_BASE_FORM',
    POST_SUCCESS: 'POST_SUCCESS',
    POST_ERROR: 'POST_ERROR',
    LOGIN_BEGIN: 'LOGIN_BEGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    EMAIL_EXISTS: 'EMAIL_EXISTS',
    EIK_EXISTS: 'EIK_EXISTS'
}