import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core';
import JobForm from './forms/JobForm';
import { jobsActionCreators } from '../../actions/jobsActionCreators';
import { getLoggedUser } from '../../helpers';

const styles = theme => ({
});

class AddOffer extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleImageDrop = this.handleImageDrop.bind(this);
    }

    componentWillMount() {
        this.props.getCategories();
    }

    handleSubmit = (e) => {
        this.props.submitJobOffer(this.props.currentOffer.imagesNames);
    }

    handleImageDrop = (e, filesToUpload) => {
        this.props.uploadImages(filesToUpload);
    }

    render() {
        const loggedUser = getLoggedUser();
        const { imagesNames } = this.props.currentOffer;

        return (
            <div>
                <h3>Публикуване на обява</h3>
                <JobForm
                    categories={this.props.categories}
                    userType={loggedUser.userType}
                    userId={loggedUser.id}
                    imagesNames={imagesNames}
                    onSubmit={this.handleSubmit}
                    onDrop={this.handleImageDrop}
                    initialValues={{
                        userId: loggedUser.id
                    }} />
            </div>
        )
    }
}

export default withStyles(styles)(connect(
    state => state.jobs,
    dispatch => bindActionCreators(jobsActionCreators, dispatch)
)(AddOffer));