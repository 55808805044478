import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Email, Phone, LocationOn } from '@material-ui/icons';
import { LinkContainer, Link } from 'react-router-bootstrap';
import { bindActionCreators } from 'redux';
import { menuActionCreators } from '../actions/menuActionCreators';
import Divider from '@material-ui/core/Divider';
import OpenMap from './Map';


const styles = theme => ({
    footer: {
        flexGrow: 1,
       
        backgroundColor: 'rgba(242,234,177,.8)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //backgroundAttachment: 'fixed',
        marginTop: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 3,
    },
    grow: {
        flexGrow: 2
    },
    paper: {
        padding: theme.spacing.unit * 4,
        backgroundColor: 'rgba(255,255,255,0.7)',
        height: 260,
    },
    externalLink: {
        color: theme.palette.primary.main,
    },
});

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fixed: false
        };
    }

    componentDidMount() {
        this.props.requestMenuElements('main');
    }

    render() {
        const props = this.props;
        const { classes } = props;

        return (
            <div className={classes.footer}>
                <Grid container spacing={8} alignItems="flex-start" justify="center">
                    <Grid item xs={12} md={2}>
                        <Typography align="center" color="primary" component="p">
                            Меню
                            </Typography> <Divider />
                        {props.elements.map(element =>
                            (element.id == 3 || element.id == 4 || element.id == 5) ?
                                <Button key={element.id} variant="text" fullWidth className={classes.menuButton} href={element.link}>
                                    {element.title}
                                </Button> :
                            <LinkContainer key={element.id} to={element.articleId !== null ? `/article/${element.articleId}` : element.link}>
                                <Button variant="text" fullWidth
                                    className={classes.menuButton}>
                                    {element.title}
                                </Button>
                            </LinkContainer>
                        )
                    }
                   </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper className={classes.paper}>
                            <Typography color="primary" component="p">
                                Контакти
                            </Typography> <Divider />
                            <Typography color="textPrimary" component="p">
                                Междууниверситетски център за развитие на кариерата на УНСС
                             </Typography>
                            <Typography color="textPrimary" component="p">
                                <LocationOn /> 1700 София, район "Студентски", ул. "8-и декември" 19, каб. 1008
                               </Typography>
                            <Typography color="textPrimary" component="p">
                                <Phone /> 02 / 8195 278
                            </Typography>
                            <Typography color="textPrimary" component="p">
                                <Email /> iccd1@unwe.bg
                            </Typography>
                            <Typography color="textPrimary" component="p">
                                <a href="https://www.facebook.com/ICCD.UNWE/" target="_blank"><img src="/img/facebook.png" width="48" /></a>
                                <a href="https://www.instagram.com/iccd.unwe/" target="_blank"><img src="/img/instagram.png" width="48" /></a>
                                <a href="https://twitter.com/IccdUnwe" target="_blank"><img src="/img/twitter.png" width="48" /></a>
                                <a href="https://www.linkedin.com/school/unwebg" target="_blank"><img src="/img/linkedin.png" width="48" /></a>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper>
                            <OpenMap/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="fb-page location" data-href="https://www.facebook.com/ICCD.UNWE/" data-tabs="timeline,message" data-width="400" data-height="260" data-small-header="true"
                            data-adat-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/ICCD.UNWE/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ICCD.UNWE/">Междууниверситетски център за развитие на кариерата на УНСС</a></blockquote></div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

//Footer.propTypes = {
//    classes: PropTypes.object.isRequired,
//};

export default withStyles(styles)(connect(
    state => state.menu,
    dispatch => bindActionCreators(menuActionCreators, dispatch)
)(Footer));