import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Chip } from '@material-ui/core';

class PageNotFound extends Component {
    render() {

        return (
            <div>
                <Card style={{ padding: 32 }}>
                    <h1><Chip label="Error 404" color="primary" /> Не е открита страница на този адрес</h1>
                    <p>Проверете дали адресът е изписан правилно. Възможно е страницата да е била преместена или премахната.</p>
                </Card>
            </div>
        )
    }
}

export default PageNotFound;