import React, { Component } from "react";
import { ListItem, ListItemText, Avatar, Chip } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import Moment from "react-moment";
import { CalendarToday, Domain } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
	title: {
		fontSize: "1.2rem"
	},
	job: {
		borderLeft: "16px solid #8D6E63"
	},
	internship: {
		borderLeft: "16px solid #3F51B5"
	},
	jobBackground: {
		backgroundColor: "#8D6E63",
		color: "#FFF",
		[theme.breakpoints.up("sm")]: {
			width: 80
		}
	},
	internshipBackground: {
		backgroundColor: "#3F51B5",
		color: "#FFF",
		[theme.breakpoints.up("sm")]: {
			width: 80
		}
	},
	contestBackground: {
		backgroundColor: "green",
		color: "#fff"
	},
	scholarshipBackground: {
		backgroundColor: "#5E09DD",
		color: "#fff"
	},
	listItem: {
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			alignItems: "stretch"
		}
	},
	avatarEmployer: {
		borderRadius: 0,
		[theme.breakpoints.down("xs")]: {
			display: "none"
		}
	}
});

class JobsTable extends Component {
	render() {
		const { classes, offers } = this.props;

		return offers.map((currentJob, i) => (
			<LinkContainer
				key={currentJob.id}
				to={`/job/details/${currentJob.id}`}
				exact>
				<ListItem button className={classes.listItem}>
					<Chip
						label={getTypeName(currentJob.typeId)}
						className={getTypeBackground(classes, currentJob.typeId)}
					/>
					<Avatar
						className={classes.avatarEmployer}
						src={
							currentJob.user.employer.companyLogo
								? `/uploads/companiesLogos/${currentJob.user.id}/${
										currentJob.user.employer.companyLogo
								  }`
								: currentJob.typeId == 1
								? "/uploads/companiesLogos/avatar_png.png"
								: "/uploads/companiesLogos/internship.png"
						}
					/>
					<ListItemText primary={currentJob.title} className={classes.title} />
					<Chip
						icon={<Domain />}
						label={currentJob.user.employer.companyName}
					/>
					<Chip
						icon={<CalendarToday />}
						label={
							<Moment format="DD.MM.YYYY" withTitle>
								{currentJob.expirationDate}
							</Moment>
						}
					/>
				</ListItem>
			</LinkContainer>
		));
	}
}

function getTypeName(type) {
	switch (type) {
		case 1:
			return "работа";
		case 2:
			return "стаж";
		case 3:
			return "конкурс";
		case 4:
			return "стипендия";
	}
}

function getTypeBackground(classes, type) {
	switch (type) {
		case 1:
			return classes.jobBackground;
		case 2:
			return classes.internshipBackground;
		case 3:
			return classes.contestBackground;
		case 4:
			return classes.scholarshipBackground;
	}
}

export default withStyles(styles)(JobsTable);
