import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { contentActionCreators } from '../actions/contentActionCreators'
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { getLoggedUser } from '../helpers';

const styles = theme => ({
    card: {
        marginBottom: 24,
    },
    media: {
        height: 100,
    },
    heading: {
        color: theme.palette.primary.light,
    },
});

class News extends Component {

    componentWillMount() {
        this.props.requestNews();
    }

    render() {
       
        return (
            <div>{this.props.isLoading === false ? NewsElements(this.props) : <CircularProgress />}</div>
        )
    }
}

function NewsElements(props) {
    const { classes } = props;
    const loggedUser = getLoggedUser();
    const adminSignedIn = (loggedUser && loggedUser.token && loggedUser.userType === 'admin') ? true : false;


    return (
        <div>
            <Typography variant="h4" gutterBottom className={classes.heading}>
                Новини
                </Typography>
            {props.news.length > 0 ? props.news.map(article =>
                <Card key={article.id} className={classes.card}>
                    <div>
                        <LinkContainer to={`/article/${article.id}`}>
                            <CardActionArea>
                                <CardMedia
                                    className={classes.media}
                                    image={article.coverImg || '/img/untitled5.jpg'}
                                    title={article.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                        {article.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </LinkContainer>

                        <CardActions>
                            <LinkContainer to={`/article/${article.id}`} exact>
                                <Button size="small" color="primary">
                                    Прочети още >>>
                                </Button>
                            </LinkContainer>
                        </CardActions>
                    </div>
                </Card>
            ) : <div>Няма намерени новини.</div>
            }
            <LinkContainer style={{ marginTop: 15 }} to={`/NewsList`}>
                <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                >
                    Вижте всички
                            </Button>
            </LinkContainer>
            {
                adminSignedIn ?
                    (
                        <LinkContainer style={{ marginTop: 15 }} to={`/create/article`}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Създаване
                            </Button>
                        </LinkContainer>
                    ) : null
            }
        </div>
    );
}

export default withStyles(styles)(connect(
    state => state.content,
    dispatch => bindActionCreators(contentActionCreators, dispatch)
)(News));
