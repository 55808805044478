import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedUser } from './';

export function StudentRoute({ component: Component, ...rest }) {

    const loggedUser = getLoggedUser();

    return (
        <Route {...rest} render={props => (
            (loggedUser && loggedUser.token
                && loggedUser.userType !== 'employer'
                && loggedUser.userType !== 'admin')
                ? (loggedUser.isActivated || props.location.pathname=='/student/profile' ? <Component {...props} /> : <Redirect to={{ pathname: '/student/profile', state: { from: props.location }}} />)
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )} />
    )
}