import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    Button,
    CircularProgress
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { profileActionCreators } from "../../actions/profileActionCreators";
import { getLoggedUser } from "../../helpers";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    HowToReg,
    RecordVoiceOver,
    Phone,
    Web,
    LocationOn,
    AlternateEmail,
    PersonAdd,
    Person,
    Info,
    Done
} from "@material-ui/icons";
import JobsTable from "./JobsTable";

const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit * 2,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        marginBottom: 40,
        marginTop: 40
    }
});

class EmployerReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {}
        };
    }

    componentWillMount() {
        const employerId = this.props.match.params.id;
        this.props.requestEmployerProfile(employerId);
    }

    handleApprove(employerId) {
        this.props.approveEmployer(employerId, this.props.redirectToEmployerList);
    }

    render() {
        const { classes } = this.props;
        const loggedUser = getLoggedUser();
        const employer = this.props.employer;

        const adminSignedIn =
            loggedUser && loggedUser.token && loggedUser.userType === "admin"
                ? true
                : false;

        if (!adminSignedIn) {
            return null;
        } else {
            return this.props.isLoading ? (
                <CircularProgress />
            ) : (
                    <Grid container spacing={0} direction="column" justify="center">
                        <Grid item xs={12}>
                            <Paper className={classes.root}>
                                <CardHeader title={`${employer.companyName}`} />
                                <List component="nav">
                                    <ListItem>
                                        <Avatar>
                                            <Info />
                                        </Avatar>
                                        <ListItemText
                                            primary="ЕИК"
                                            secondary={employer.bulstat}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <Person />
                                        </Avatar>
                                        <ListItemText
                                            primary="Управител"
                                            secondary={employer.managerName}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <AlternateEmail />
                                        </Avatar>
                                        <ListItemText
                                            primary="E-mail"
                                            secondary={employer.email}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <LocationOn />
                                        </Avatar>
                                        <ListItemText
                                            primary="Адрес"
                                            secondary={employer.contactAddress}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <Web />
                                        </Avatar>
                                        <ListItemText
                                            primary="Website"
                                            secondary={employer.website}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <RecordVoiceOver />
                                        </Avatar>
                                        <ListItemText
                                            primary="Лице за кореспонденция"
                                            secondary={employer.contactPersonName}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <Phone />
                                        </Avatar>
                                        <ListItemText
                                            primary="Телефон"
                                            secondary={employer.phone}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Avatar>
                                            <PersonAdd />
                                        </Avatar>
                                        <ListItemText
                                            primary="Дали е компания за подбор на персонал"
                                            secondary={employer.isHrCompany ? "Да" : "Не"}
                                            secondaryTypographyProps={{ variant: "headline" }}
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                                <Card>
                                    <CardActions>
                                        {!employer.isApproved && (
                                            <Button
                                                onClick={() => this.handleApprove(employer.employerId)}
                                                variant="contained"
                                                color="primary">
                                                Одобри <Done />
                                            </Button>
                                        )}
                                    </CardActions>
                                    <CardActions>
                                        <Button variant="contained" color="primary" href={`/employer/profile/${employer.id}`}>
                                            Редактиране на профила
                                </Button>
                                    </CardActions>
                                </Card>
                                {employer.isApproved && employer.offersCreated.length>0 && (
                                    <Card>
                                        <CardHeader title="Обяви" />
                                        <List dense>
                                            <JobsTable offers={employer.offersCreated} />
                                        </List>
                                    </Card>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                );
        }
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(
        state => state.profiles,
        dispatch => bindActionCreators(profileActionCreators, dispatch)
    )(EmployerReview)
);
