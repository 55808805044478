import React, { Component } from 'react';
import { MenuItem, Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/studentRegValidate';
import asyncValidate from './validations/asyncValidate';
import { renderTextField, renderSelectField, renderError } from '../../../helpers';


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        width: 550,
    },
    withoutLabel: {
        marginTop: theme.spacing.unit * 3,
    },
    textField: {
        flexBasis: 200,
    },
});


class StudentDetailsForm extends Component {

    render() {
        const { handleSubmit, pristine, previousPage, submitting } = this.props;

        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom align="center">
                    Профил на студента
                </Typography>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Field
                            name="facultyNumber"
                            component={renderTextField}
                            label="Факултетен номер"
                        />
                    </Grid>
                    <Grid item xs={12}>                        
                        <Field
                            name="degree"
                            component={renderSelectField}
                            label="Образователно-квалификационна степен"
                            >
                            <MenuItem value="Бакалавър">Бакалавър</MenuItem>
                            <MenuItem value="Професионален бакалавър">Професионален бакалавър</MenuItem>
                            <MenuItem value="Магистър">Магистър</MenuItem>
                        </Field>
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="school"
                            component={renderTextField}
                            label="Учебно заведение"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="speciality"
                            component={renderTextField}
                            label="Специалност"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="phone"
                            component={renderTextField}
                            label="Телефонен номер"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label><Field name="isGraduated" component="input" type="radio" value="true" /> Учащ</label>
                        <label><Field name="isGraduated" component="input" type="radio" value="false" /> Завършил</label>
                        <Field name="isGraduated" component={renderError} />
                    </Grid>
                    <Grid item xs={12} align="right">
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginRight: 10 }}
                            onClick={previousPage}
                            className="next"
                            align="inline"
                        >
                            Назад
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={handleSubmit}
                            className="next"
                            align="inline"
                        >
                            Регистрация
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}


export default withStyles(styles)(reduxForm({
    form: 'StudentRegistrationForm', // a unique identifier for this form
    destroyOnUnmount: false, // preserve form data
    validate,
    asyncValidate
})(StudentDetailsForm));