import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { LinkContainer } from 'react-router-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Storybook.css';
import Typography from '@material-ui/core/Typography';
import { Work } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { contentActionCreators } from '../actions/contentActionCreators'
import { bindActionCreators } from 'redux';
import { CircularProgress } from '@material-ui/core';
import Moment from 'react-moment';
import { getLoggedUser } from '../helpers';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    card: {
        marginBottom: 24,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        color: theme.palette.primary.light,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50
        }
    },
});

class EventsList extends Component {

    componentWillMount() {
        this.props.requestAllEvents();
    }

    render() {

        return (
            <div>{this.props.isLoading === false ? EventsElements(this.props) : <CircularProgress />}</div>
        )
    }
}

function EventsElements(props) {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h4" gutterBottom className={classes.heading}>
                Предстоящо
            </Typography>

            <VerticalTimeline layout="1-column" className="VerticalTimeLine">
                {props.events.length > 0 ? (props.events.map(article =>
                    <VerticalTimelineElement
                        key={article.id}
                        className="vertical-timeline-element--work VerticalElement"
                        date={<Moment format="DD.MM.YYYY" withTitle>{article.eventDate}</Moment>}
                        iconStyle={{ background: '#760e26', color: '#fff' }}
                        icon={<Work />}
                    >
                        <h3>
                            <Link to={`/article/${article.id}`}>{article.title}</Link>
                        </h3>
                        <p>{article.eventLocation}</p>
                    </VerticalTimelineElement>
                )) : <VerticalTimelineElement
                    className="vertical-timeline-element--work VerticalElement"
                    iconStyle={{ background: '#760e26', color: '#fff' }}
                    icon={<Work />}>
                        Няма намерени събития.
                     </VerticalTimelineElement>}
            </VerticalTimeline>
        </div>
    );
}

export default withStyles(styles)(connect(
    state => state.content,
    dispatch => bindActionCreators(contentActionCreators, dispatch)
)(EventsList));