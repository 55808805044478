import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button, Grid, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { jobsActionCreators } from '../../actions/jobsActionCreators';
import { getLoggedUser } from '../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'react-moment';


const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit * 2,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        marginBottom: 40,
        marginTop: 40,
    },
});

class EmployerOffers extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
        const loggedUser = getLoggedUser();

        this.props.requestEmployer(loggedUser.id);
    }

    render() {
        const { classes, theme, isLoading } = this.props;
        const loggedUser = getLoggedUser();
        const employer = this.props.employer;

        if (!loggedUser && !loggedUser.token) {
            return (null);
        } else {

            return (isLoading ? <CircularProgress /> : 
                <div>
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item xs={8}>
                            <Paper className={classes.root}>
                                <Typography variant="h4" align="center" className={classes.heading}>
                                    Нашите обяви
                                </Typography>
                                <Grid item xs={11} align="right">
                                    {employer.isApproved ? (<LinkContainer to="/job/add">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            Добави
                                        </Button>
                                    </LinkContainer>) : null}
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Заглавие</TableCell>
                                                <TableCell>Качена на</TableCell>
                                                <TableCell>Изтича на</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {employer.offersCreated && employer.offersCreated.length > 0 ?
                                                employer.offersCreated.map(offer =>
                                                    <TableRow key={offer.id}>
                                                        <TableCell>
                                                            <LinkContainer to={`/job/details/${offer.id}`} exact>
                                                                <Button>{offer.title}</Button>
                                                            </LinkContainer>
                                                        </TableCell>
                                                        <TableCell><Moment format="DD.MM.YYYY" withTitle>{offer.createdOn}</Moment></TableCell>
                                                        <TableCell><Moment format="DD.MM.YYYY" withTitle>{offer.expirationDate}</Moment></TableCell>
                                                    </TableRow>) :
                                                <TableRow>
                                                    <TableCell>Вие нямате качени обяви.</TableCell>
                                                </TableRow>}
                                        </TableBody>
                                    </Table>
                                    
                                </Grid>
                                
                            </Paper>
                        </Grid>
                    </Grid>                    
                </div>
            );
        }
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    state => state.jobs,
    dispatch => bindActionCreators(jobsActionCreators, dispatch)
)(EmployerOffers));