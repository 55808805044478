import { config } from '../../../../helpers/config';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const asyncValidate = (values /*, dispatch */) => {
    return sleep(5).then(async () => {
        if (values.email !== undefined) {
            const url = `${config.base}api/Authentication/UserExists/${encodeURI(values.email)}`;
            const response = await fetch(url);
            const emailExists = await response.json();

            if (emailExists) {
                throw { email: 'Този E-mail вече е използван за регистрация.' }
            }
            
        }
        if (values.bulstat !== undefined) {
            const url = `${config.base}api/Authentication/CheckEik/${values.bulstat}`;
            const response = await fetch(url);
            const eikExists = await response.json();

            if (eikExists.result) {
                throw { bulstat: 'Компания с това ЕИК е вече регистрирана.' }
            }
        }
    })
}

export default asyncValidate