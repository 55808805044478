import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Grid,
	Paper,
	withStyles,
	Button,
	List,
	ListItem,
	ListItemText,
	Avatar,
	ListItemSecondaryAction,
	Chip,
	CircularProgress
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { jobsActionCreators } from "../../actions/jobsActionCreators";
import Moment from "react-moment";
import FilterCategoriesForm from "./forms/FilterCategoriesForm";
import { CalendarToday, Domain } from "@material-ui/icons";
import JobsTable from "./JobsTable";
import { getLoggedUser } from "../../helpers";

const styles = theme => ({
	root: {
		flexGrow: 1,
		textAlign: "center"
	},
	paper: {
		padding: theme.spacing.unit * 2,
		overflow: "hidden",
		textAlign: "justify"
	},
	coverImage: {
		width: "100%"
	},
	socialShare: {
		color: theme.palette.primary.main
	}
});
class JobsList extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.getTypeId = this.getTypeId.bind(this);
	}

	loadData(type) {
		this.props.requestJobsOffers(type);
		this.props.getCategories();
	}

	componentDidMount() {
		this.loadData(this.getTypeId(this.props.match.params.type));
	}

	componentWillReceiveProps(next) {
		if (next.match.params.type === this.props.match.params.type) return;
		this.loadData(this.getTypeId(next.match.params.type));
	}

	handleSubmit() {
		this.props.filterJobOffers();
	}

	getTypeId(typeName) {
		const types = { "": 1, contests: 3, scholarships: 4 };
		return types[typeName] || 1;
	}

	getTypeTitle(type) {
		switch (type) {
			case "contests":
				return "Конкурси";
			case "scholarships":
				return "Стипендии";
			default:
				return "Обяви за стаж и работа";
		}
	}

	render() {
		const { classes, jobOffers, categories, employer } = this.props;
		const offerTypeText = this.getTypeTitle(this.props.match.params.type);
		const loggedUser = getLoggedUser();

		return this.props.isLoading ? (
			<div>
				<CircularProgress size={100} />
			</div>
		) : (
			<div className={classes.root}>
				<h2>{offerTypeText}</h2>
				<Grid container spacing={24}>
					{false && (
						<Grid item xs={12} sm={12}>
							<Paper className={classes.paper}>
								<FilterCategoriesForm
									categories={categories}
									onSubmit={this.handleSubmit}
								/>
							</Paper>
						</Grid>
					)}
					<Grid item xs={12} align="right">
						{loggedUser && loggedUser.userType == "admin" ? (
							<LinkContainer to="/job/add">
								<Button variant="contained" color="primary">
									Добави
								</Button>
							</LinkContainer>
						) : null}
					</Grid>
					<Grid item xs={12} sm={12}>
						<Paper className={classes.paper}>
							<List dense>
								{jobOffers && jobOffers.length > 0 ? (
									<JobsTable offers={jobOffers} />
								) : (
									<ListItem>
										Няма налични{" "}
										{offerTypeText.toLowerCase()}
									</ListItem>
								)}
							</List>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		state => state.jobs,
		dispatch => bindActionCreators(jobsActionCreators, dispatch)
	)(JobsList)
);
