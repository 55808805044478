import { profilesConstants } from '../constants/profilesConstants';
import { push } from 'react-router-redux'
import { reset } from 'redux-form';
import { authHeader, getBase64, handleResponse, handleError } from '../helpers';
import {notify} from '../helpers/notification';
import { config } from '../helpers/config';

export const profileActionCreators = {
    requestLogs: (page = 1, count = 100, searchText = '', sortColumn = 0, sortDirection = 'desc') => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_LOGS_BEGIN });

        let url = `${config.base}api/Users/GetLogs?page=${page}&count=${count}&searchText=${searchText}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;
        let response = await fetch(url, {
            headers: authHeader()
        });

        const log = await response.json();

        url = `${config.base}api/Users/CountLogs`;
        response = await fetch(url, {
            headers: authHeader()
        });

        const countLogs = await response.json();

        dispatch({ type: profilesConstants.REQUEST_LOGS, logs: log, countLogs: countLogs.countLogs, page: page });
    },

    requestEmployerProfile: (employerId) => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_EMPLOYER_BEGIN });

        const url = `${config.base}api/Users/GetEmployerProfile?employerId=${employerId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const employer = await response.json();

        employer.agreementStartDate = employer.agreementStartDate ? employer.agreementStartDate.split('T')[0] : undefined;
        employer.agreementEndDate = employer.agreementEndDate ? employer.agreementEndDate.split('T')[0] : undefined;

        dispatch({ type: profilesConstants.REQUEST_EMPLOYER, employer });
    },

    requestEmployers: (partnersOnly = 0, page = 1, count = 100, searchText = '', sortColumn = 0, sortDirection = 'desc') => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_EMPLOYERS_BEGIN });

        let url = `${config.base}api/Users/GetEmployers?page=${page}&count=${count}&searchText=${searchText}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&partnersOnly=${partnersOnly}`;
        let response = await fetch(url, {
            headers: authHeader()
        });

        const еmployers = await response.json();

        url = `${config.base}api/Users/CountEmployers?partnersOnly=${partnersOnly}`;
        response = await fetch(url, {
            headers: authHeader()
        });

        const countEmployers = await response.json();

        dispatch({ type: profilesConstants.REQUEST_EMPLOYERS, employers: еmployers, countEmployers: countEmployers.countEmployers, page: page });
    },

    requestStudent: (studentId) => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_STUDENT_BEGIN });
        
        const url = `${config.base}api/Users/GetStudentProfile?studentId=${studentId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const student = await response.json();

        dispatch({ type: profilesConstants.REQUEST_STUDENT, student });
    },

    requestStudentWithOffers: (studentId) => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_STUDENT_BEGIN });

        const url = `${config.base}api/Users/GetStudent?studentId=${studentId}`;
        const response = await fetch(url, {
            headers: authHeader()
        });

        const student = await response.json();

        dispatch({ type: profilesConstants.REQUEST_STUDENT, student });
    },

    requestAllStudents: (page=1, count=100, searchText='', sortColumn=0, sortDirection='desc') => async (dispatch) => {
        dispatch({ type: profilesConstants.REQUEST_ALLSTUDENTS_BEGIN });

        let url = `${config.base}api/Users/GetAllStudents?page=${page}&count=${count}&searchText=${searchText}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;
        let response = await fetch(url, {
            headers: authHeader()
        });

        const allStudents = await response.json();

        url = `${config.base}api/Users/CountStudents`;
        response = await fetch(url, {
            headers: authHeader()
        });

        const countStudents = await response.json();

        dispatch({ type: profilesConstants.REQUEST_ALLSTUDENTS, allStudents: allStudents, countStudents: countStudents.countStudents, page: page });
    },

    changePassword: (userType) => async (dispatch, getState) => {

        const { form } = getState();
        let baseForm;

        if (userType === 'student') {

            baseForm = form.StudentBaseForm.values;
        } else if (userType === 'employer') {

            baseForm = form.EmployerBaseForm.values;
        }

        if (baseForm.oldPassword === baseForm.newPassword) {

            dispatch(notify({ message: 'Новата ви парола не може да бъде като текущата!', status: 400 }));
            return;
        }

        const url = `${config.base}api/Users/ChangePassword`;

        return fetch(url, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(baseForm)
        }).then((res) => {
            if (res.ok) {
                dispatch({ type: profilesConstants.EDIT_SUCCESS });

                if (userType === 'student') {

                    dispatch(reset('StudentBaseForm'));
                    dispatch(push('/student/profile'));
                } else if (userType === 'employer') {

                    dispatch(reset('EmployerBaseForm'));
                    dispatch(push('/employer/profile'));
                }

                dispatch(notify({ message: 'Паролата ви беше променена успешно!', status: 200 }));
            } else {
                dispatch({ type: profilesConstants.EDIT_ERROR });
                dispatch(notify({ message: 'Възникнала е грешка, моля опитайте отново.', status: 400 }));
            }
        })
    },

    changeEmployerDetails: (userType) => async (dispatch, getState) => {

        const url = `${config.base}api/Users/ChangeEmployerDetails`;
        const { form } = getState();
        const detailsForm = form.EmployerDetailsForm.values;
        const files = detailsForm.companyLogoFile;

        if (files != null && files.length > 0) {
            let promise = getBase64(files[0]);
            let fileAsBase64 = await promise;

            detailsForm.companyLogo = files[0].name;
            detailsForm.companyLogoFile = fileAsBase64.substr(fileAsBase64.indexOf(',') + 1);
        }

        return await fetch(url, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(detailsForm)
        }).then((res) => {
            if (res.ok) {
                dispatch({ type: profilesConstants.EDIT_SUCCESS });
                if (userType === "employer") {
                    dispatch(push('/employer/profile'));
                } else if (userType === "admin") {
                    dispatch(push('/employers/list'));
                }
                dispatch(notify({ message: 'Детайлите на компанията бяха променени успешно!', status: 200 }));
            } else {
                dispatch({ type: profilesConstants.EDIT_ERROR });
                dispatch(notify({ message: 'Възникнала е грешка, моля опитайте отново.', status: 400 }));
            }
        })
    },

    changeEmployerCorespond: () => async (dispatch, getState) => {

        const { form } = getState();
        const corespondForm = form.EmployerCorespondForm.values;

        const url = `${config.base}api/Users/ChangeEmpCorespondDetails`;

        return fetch(url, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(corespondForm)
        }).then((res) => {
            if (res.ok) {
                dispatch({ type: profilesConstants.EDIT_SUCCESS });
                dispatch(push('/employer/profile'));
                dispatch(notify({ message: 'Детайлите за кореспонденция на компанията бяха променени успешно!', status: 200 }));
            } else {
                dispatch({ type: profilesConstants.EDIT_ERROR });
                dispatch(notify({ message: 'Възникнала е грешка, моля опитайте отново.', status: 400 }));
            }
        })
    },

    changeStudentDetails: () => async (dispatch, getState) => {

        const { form } = getState();
        const detailsForm = form.StudentDetailsForm.values;
        const url = `${config.base}api/Users/ChangeStudentDetails`;
        let files;

        if (detailsForm.cvFile && Array.isArray(detailsForm.cvFile)) {
            files = detailsForm.cvFile;
            let promise = getBase64(files[0]);
            let fileAsBase64 = await promise;

            detailsForm.cv = files[0].name;
            detailsForm.cvFile = fileAsBase64.substr(fileAsBase64.indexOf(',') + 1);
        }

        return fetch(url, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(detailsForm)
        }).then(handleResponse, handleError)
            .then(student => {

                return student;
            }).then(student => {
                dispatch({ type: profilesConstants.EDIT_STUDENT_SUCCESS, student });
                dispatch(notify({ message: 'Детайлите за личните ви данни бяха променени успешно!', status: 200 }));
            }, error => {
                dispatch({ type: profilesConstants.EDIT_ERROR, error });
                dispatch(notify({ message: 'Възникнала е грешка, моля опитайте отново.', status: 400 }));
            });
    },

    approveEmployer: (employerId, success) => async (dispatch) => {
        const url = `${config.base}api/Users/ApproveEmployer?employerId=${employerId}`;

        return fetch(url, {
            method: 'GET',
            headers: authHeader()
        }).then(handleResponse, handleError)
            .then(employers => {
                return employers;
            }).then(employers => {
                //dispatch({ type: profilesConstants.REQUEST_EMPLOYERS, employers });
                dispatch(notify({ message: 'Успешно одобрен работодател!', status: 200 }));
                success();
            }, error => {

                dispatch({ type: profilesConstants.EDIT_ERROR, error });
                dispatch(notify({ message: 'Възникнала е грешка, моля опитайте отново.', status: 400 }));
            });
    },

    redirectToEmployerList: () => async (dispatch) => {
        dispatch(push('/employers/list'));
    },

    redirectToStudentsReview: (id) => async (dispatch) => {
        dispatch(push('/student/review/'+ id))
    },

    redirectToEmployerReview: (id) => async (dispatch) => {
        dispatch(push('/employer/review/' + id));
    },

    getStudentCount: () => async (dispatch) => {
        const url = `${config.base}api/Users/CountStudents`;

        return fetch(url, {
            method: 'GET',
            headers: authHeader()
        }).then(handleResponse, handleError)
            .then(students => {
                dispatch({ type: profilesConstants.STATISTICS, countStudents: students.countStudents });
            });
    },
    getEmployersCount: () => async (dispatch) => {
        const url = `${config.base}api/Users/CountEmployers`;

        return fetch(url, {
            method: 'GET',
            headers: authHeader()
        }).then(handleResponse, handleError)
            .then(employers_count => {
                dispatch({ type: profilesConstants.STATISTICS_EMP, countEmployers: employers_count.countEmployers });
            });
    },
}