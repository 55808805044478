import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authenticationActionCreators } from '../../actions/authentication/authenticationActionCreators';
import EmployerBaseForm from './forms/EmployerBaseForm';
import EmployerCompanyDetails from './forms/EmployerCompanyDetails';
import EmployerCorrespondDetails from './forms/EmployerCorrespondDetails';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
            width: 600,
            marginLeft: 435,
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            marginTop: theme.spacing.unit * 6,
            marginBottom: theme.spacing.unit * 6,
            padding: theme.spacing.unit * 3,
        },
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit,
    },
});

class EmployerRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        };

        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
        this.setState({ page: this.state.page - 1 });
    }

    handleSubmit() {
        this.props.registerEmployer();
    };

    render() {
        const { classes } = this.props;
        const { page } = this.state;

        return (
            <React.Fragment>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography variant="h4" align="center">
                            Регистрация на работодател
                        </Typography>
                        {page === 1 && <EmployerBaseForm onSubmit={this.nextPage} />}
                        {page === 2 && <EmployerCompanyDetails previousPage={this.previousPage} onSubmit={this.nextPage} />}
                        {page === 3 && <EmployerCorrespondDetails previousPage={this.previousPage} onSubmit={this.handleSubmit} />}
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(connect(
    state => state.authentication,
    dispatch => bindActionCreators(authenticationActionCreators, dispatch)
)(EmployerRegistration));