import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Grid,
    Paper,
    withStyles,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Avatar,
    CircularProgress
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import Moment from "react-moment";
import { getLoggedUser } from "../../helpers";
import { jobsActionCreators } from "../../actions/jobsActionCreators";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        overflow: "hidden",
        textAlign: "left"
    },
    paperError: {
        padding: theme.spacing.unit * 2,
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    coverImage: {
        width: "100%"
    },
    socialShare: {
        color: theme.palette.primary.main
    },
    logo: {
        width: "100%",
        height: "auto",
        borderRadius: 0,
    }
});

class JobOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    componentWillMount() {
        this.props.getOffer(this.props.match.params.id);
    }

    componentWillReceiveProps(next) {
        if (next.match.params.id === this.props.match.params.id) return;
        this.props.getOffer(this.props.match.params.id);
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleStudentApplication(offerId, studentEmail) {
        this.props.addApplicant(offerId, studentEmail);
    }

    handleDelete(offerId) {
        this.props.deleteOffer(offerId);
    }

    render() {
        const currentOffer = this.props.currentOffer;
        const { classes } = this.props;
        const employerUser = currentOffer.user;

        const loggedUser = getLoggedUser();
        const employerAuthorSignedIn =
            loggedUser &&
            loggedUser.token &&
            (loggedUser.userType === "admin" ||
                (loggedUser.userType === "employer" &&
                    loggedUser.id === currentOffer.userId));

        const studentSignedIn =
            loggedUser && loggedUser.token && loggedUser.userType === "student"
                ? true
                : false;
        let applicants = currentOffer.applicants;

        return this.props.isLoading ? (
            <div>
                <CircularProgress size={100} />
            </div>
        ) : (
                <div className={classes.root}>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={3}>
                            {employerAuthorSignedIn ? (
                                <div style={{ marginBottom: 15 }}>
                                    <LinkContainer
                                        style={{ marginTop: 15 }}
                                        to={`/job/edit/${currentOffer.id}`}>
                                        <Button fullWidth variant="contained" color="primary">
                                            Редактиране
									</Button>
                                    </LinkContainer>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{ marginTop: 15 }}
                                        onClick={this.handleClickOpen}>
                                        Изтриване
								</Button>
                                    <Dialog
                                        open={this.state.open}
                                        onClose={this.handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <DialogTitle id="alert-dialog-title">
                                            {"Изтриване на обява"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Сигурни ли сте, че искате да изтриете тази обява?
										</DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={this.handleClose}
                                                variant="contained"
                                                color="default">
                                                Отказ
										</Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => this.handleDelete(currentOffer.id)}>
                                                Изтриване
										</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            ) : (
                                    <div />
                                )}
                            {currentOffer.typeId <= 2 ? (
                                studentSignedIn ? (
                                    !currentOffer.hasCurrentStudentAppliedFor ? (
                                        <div style={{ marginBottom: 15 }}>
                                            <Button
                                                fullWidth
                                                variant="raised"
                                                size="large"
                                                color="primary"
                                                onClick={() =>
                                                    this.handleStudentApplication(
                                                        currentOffer.id,
                                                        loggedUser.email
                                                    )
                                                }>
                                                Кандидатствай
										</Button>
                                            <Paper className={classes.paper}>
                                                Внимателно прочетете условията за кандидатстване, възможно
                                                е работодателят да има допълнителни изисквания
										</Paper>
                                        </div>
                                    ) : (
                                            <Paper className={classes.paperError}>
                                                Вече сте кандидатствали по тази обява. Работодателят ще има
                                                възможност да разгледа Вашия профил и автобиография.
									</Paper>
                                        )
                                ) : (
                                        <Paper className={classes.paperError}>
                                            За да кандидатствате по обявата, трябва да сте регистриран
                                            студент в платформата
								</Paper>
                                    )
                            ) : null}
                            <Paper className={classes.paper}>
                                {employerUser && employerUser.employer.companyLogo && (
                                    <Avatar
                                        className={classes.logo}
                                        src={`/uploads/companiesLogos/${employerUser.id}/${
                                            employerUser.employer.companyLogo
                                            }`}
                                    />
                                )}
                                <h2>{employerUser ? employerUser.employer.companyName : "-"}</h2>
                                <h3>{`Обява за ${
                                    currentOffer.typeId === 1
                                        ? "работа"
                                        : currentOffer.typeId === 2
                                            ? "стаж"
                                            : currentOffer.typeId === 3
                                                ? "конкурс"
                                                : "стипендия"
                                    }`}</h3>
                                <h4>
                                    Публикувано на:
								<Moment format="DD.MM.YYYY" withTitle>
                                        {currentOffer.createdOn}
                                    </Moment>
                                </h4>
                                <h4>
                                    Изтича на:
								<Moment format="DD.MM.YYYY" withTitle>
                                        {currentOffer.expirationDate}
                                    </Moment>
                                </h4>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Paper className={classes.paper}>
                                {currentOffer.component === undefined && (
                                    <div>
                                        <h2>{currentOffer.title}</h2>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: currentOffer.content }}
                                        />
                                    </div>
                                )}
                            </Paper>
                        </Grid>
                        {employerAuthorSignedIn ? (
                            <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                    <h3>Кандидати по тази обява</h3>
                                    <ol>
                                        {applicants &&
                                            applicants.length > 0 &&
                                            applicants.map((applicant, i) => (
                                                <li>
                                                    <LinkContainer
                                                        to={`/student/review/${applicant.studentUserId}`}>
                                                        <Button>
                                                            {applicant.studentUser.student.firstName}{" "}
                                                            {applicant.studentUser.student.lastName}
                                                        </Button>
                                                    </LinkContainer>
                                                </li>
                                            ))}
                                    </ol>
                                </Paper>
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            );
    }
}

export default withStyles(styles)(
    connect(
        state => state.jobs,
        dispatch => bindActionCreators(jobsActionCreators, dispatch)
    )(JobOffer)
);
