import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contentActionCreators } from "../../actions/contentActionCreators";
import {
    Grid,
    Paper,
    withStyles,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { CalendarToday, LocationOn } from "@material-ui/icons";
import Moment from "react-moment";
import Submenu from "../Submenu";
import Team from "../content/Team";
import { menuActionCreators } from "../../actions/menuActionCreators";
import { Twitter } from "react-social-sharing";
import { Facebook } from "react-social-sharing";
import { Linkedin } from "react-social-sharing";
import MetaTags from 'react-meta-tags';
import striptags from 'striptags';
import { getLoggedUser } from "../../helpers";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit * 2,
        overflow: "hidden",
        textAlign: "justify"
    },
    coverImage: {
        width: "100%"
    },
    socialShare: {
        color: theme.palette.primary.main,
        "& span": {
            display: "none"
        }
    }
});

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    componentWillMount() {
        this.props.getArticle(this.props.match.params.id);
        this.props.requestSubmenuElements(this.props.match.params.id);
    }

    componentWillReceiveProps(next) {
        if (next.match.params.id === this.props.match.params.id) return;
        this.props.getArticle(next.match.params.id);
        this.props.requestSubmenuElements(next.match.params.id);
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete(articleId) {
        this.props.deleteArticle(articleId);
    }

    render() {
        const article = this.props.articleToRead;
        const description = striptags(article.content).substring(0, 200);
        const { classes, menu } = this.props;
        const loggedUser = getLoggedUser();
        const adminSignedIn =
            loggedUser && loggedUser.token && loggedUser.userType === "admin"
                ? true
                : false;

        return (
            <div className={classes.root}>
                <MetaTags>
                    <title>{article.title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={article.title} />
                    {article.coverImg && <meta property="og:image" content={article.coverImg} />}
                </MetaTags>

                <Grid container spacing={24}>
                    <Grid item xs={12} sm={3}>
                        {menu.submenuElements !== undefined &&
                            menu.submenuElements.length > 0 ? (
                                <Submenu elements={menu.submenuElements} />
                            ) : (
                                <Paper className={classes.paper}>
                                    <h4>
                                        Публикувано на{" "}
                                        <Moment format="DD.MM.YYYY HH:mm" withTitle>
                                            {article.createdOn}

                                        </Moment>
                                    </h4>
                                    <div className={classes.socialShare}>
                                        <Twitter
                                            link={`https://iccd.unwe.bg/article/${
                                                article.id
                                                }`}
                                        />
                                        <Facebook
                                            link={`https://iccd.unwe.bg/article/${
                                                article.id
                                                }`}
                                        />
                                        <Linkedin
                                            link={`https://iccd.unwe.bg/article/${
                                                article.id
                                                }`}
                                        />
                                    </div>
                                </Paper>
                            )}
                        {adminSignedIn ? (
                            <div>
                                <LinkContainer
                                    style={{ marginTop: 15 }}
                                    to={`/article/edit/${article.id}`}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        Редактиране
									</Button>
                                </LinkContainer>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 15 }}
                                    onClick={this.handleClickOpen}>
                                    Изтриване
								</Button>
                                <Dialog
                                    open={this.state.open}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title">
                                        {"Изтриване на статия"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Сигурни ли сте, че искате да
											изтриете тази статия?
										</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={this.handleClose}
                                            variant="contained"
                                            color="default">
                                            Отказ
										</Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                this.handleDelete(article.id)
                                            }>
                                            Изтриване
										</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        ) : null}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={
                            menu.submenuElements !== undefined &&
                                menu.submenuElements.length > 0
                                ? 8
                                : 6
                        }>
                        <Paper className={classes.paper}>
                            {article.component == undefined && (
                                <div>
                                    <h2>{article.title}</h2>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: article.content
                                        }}
                                    />
                                </div>
                            )}
                            {article.component !== undefined &&
                                displayInnerComponent(article.component)}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {menu.submenuElements !== undefined &&
                            menu.submenuElements.length > 0 ? (
                                <Typography />
                            ) : (
                                <Paper className={classes.paper}>
                                    {article.eventDate != undefined && (
                                        <h3>
                                            <CalendarToday />{" "}
                                            <Moment format="DD.MM.YYYY" withTitle>
                                                {article.eventDate}
                                            </Moment>
                                            {article.eventDateEnd && 
                                                <Moment format=" - DD.MM.YYYY" withTitle>
                                                     {article.eventDateEnd}
                                                </Moment>
                                            }
                                        </h3>
                                    )}
                                    {article.eventLocation != undefined && (
                                        <h3>
                                            <LocationOn /> {article.eventLocation}
                                        </h3>
                                    )}
                                    {article.coverImg != undefined && (
                                        <img
                                            src={article.coverImg}
                                            alt={article.title}
                                            className={classes.coverImage}
                                        />
                                    )}
                                </Paper>
                            )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

function displayInnerComponent(component) {
    const components = {
        Team: <Team />
    };
    return components[component];
}

export default withStyles(styles)(
    connect(
        state => {
            return {
                ...state.content,
                menu: state.menu
            };
        },
        dispatch =>
            bindActionCreators(
                { ...contentActionCreators, ...menuActionCreators },
                dispatch
            )
    )(Article)
);
