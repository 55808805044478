import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/employerCdValidation';
import { renderTextField } from '../../../../helpers';

const styles = theme => ({
});

class EmployerCorespondForm extends Component {

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Grid container spacing={8} style={{ marginTop: 40, marginBottom: 10 }}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="contactPersonName"
                                fullWidth
                                component={renderTextField}
                                label="Лице за кореспонденция"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="contactPersonTitle"
                                fullWidth
                                component={renderTextField}
                                label="Длъжност"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="contactAddress"
                                fullWidth
                                component={renderTextField}
                                label="Адрес за кореспонденция"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Field
                                name="phone"
                                fullWidth
                                component={renderTextField}
                                label="Телефон"
                            />
                        </Grid>
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                align="inline"
                            >
                                Редактирай
                        </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        )
    }
}

export default withStyles(styles)(reduxForm({
    form: 'EmployerCorespondForm', // a unique identifier for this form
    destroyOnUnmount: true, // preserve form data
    validate,
    enableReinitialize: true
})(EmployerCorespondForm));