import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { FormHelperText, Grid } from '@material-ui/core';
import { getBlobFromBase64 } from './getBlobFromBase64';

export const renderMultiDropzoneImageField = (field) => {

    return (
        <Grid container>
            <Grid item xs={12} sm={5}>
                <Dropzone
                    name={field.name}
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    multiple={true}
                    onDropAccepted={(filesToUpload, e) => field.input.onChange(filesToUpload) }
                >
                    <div>Привлачете вашите снимки тук, или кликнете за да селектирате файловете директно. Позволените формати са: .jpg, .jpeg, .png, .pdf</div>
                </Dropzone>
            </Grid>
            <Grid item xs={12} sm={4}>
                                              
            </Grid>
        </Grid>
    );
}