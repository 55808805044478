import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	Avatar,
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
} from "@material-ui/core";
import {
	AlternateEmail,
	ExpandLess,
	ExpandMore,
	Phone,
	MeetingRoom,
} from "@material-ui/icons";

const styles = (theme) => ({
	root: {
		width: "100%",
		//maxWidth: 1000,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing.unit * 10,
	},
	avatar: {
		width: 120,
		height: 140,
		//height: '10%',
	},
	title: {
		textAlign: "left",
	},
});

class TeamList extends React.Component {
	state = {
		open1: false,
		open2: false,
		open3: false,
		open4: false,
		open5: false,
		open6: false,
	};

	handleClick = () => {
		this.setState((state) => ({ open1: !state.open1 }));
	};
	handleClick_1 = () => {
		this.setState((state) => ({ open2: !state.open2 }));
	};
	handleClick_2 = () => {
		this.setState((state) => ({ open3: !state.open3 }));
	};
	handleClick_3 = () => {
		this.setState((state) => ({ open4: !state.open4 }));
	};
	handleClick_4 = () => {
		this.setState((state) => ({ open5: !state.open5 }));
	};
	handleClick_5 = () => {
		this.setState((state) => ({ open6: !state.open6 }));
	};

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<List
					component="nav"
					subheader={
						<ListSubheader component="div">
							<h1>Директор</h1>
						</ListSubheader>
					}
				>
					<ListItem button onClick={this.handleClick}>
						<ListItemIcon>
							<Avatar
								alt="Atanas Dimitrov"
								src="/img/atanas.jpg"
								className={classes.avatar}
							/>
						</ListItemIcon>
						<ListItemText
							inset
							primary="Атанас Димитров"
							primaryTypographyProps={{ variant: "headline" }}
						/>
						{this.state.open1 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={this.state.open1} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<MeetingRoom />
								</ListItemIcon>
								<ListItemText inset primary="Кабинет" secondary="1008" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText inset primary="Телефон" secondary="02 8195 278" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<AlternateEmail />
								</ListItemIcon>
								<ListItemText
									inset
									primary="E-mail"
									secondary="iccd1@unwe.bg"
								/>
							</ListItem>
						</List>
					</Collapse>
				</List>

				<List
					component="nav"
					subheader={
						<ListSubheader component="div">
							<h1>Заместник-директор</h1>
						</ListSubheader>
					}
				>
					<ListItem button onClick={this.handleClick_1}>
						<ListItemIcon>
							<Avatar
								alt="Galina Dimitrova"
								src="/img/galina.jpg"
								className={classes.avatar}
							/>
						</ListItemIcon>
						<ListItemText
							inset
							primary="доц. д-р Галина Димитрова "
							primaryTypographyProps={{ variant: "headline" }}
						/>
						{this.state.open2 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={this.state.open2} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<MeetingRoom />
								</ListItemIcon>
								<ListItemText inset primary="Кабинет" secondary="1008" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText inset primary="Телефон" secondary="02 8195 293" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<AlternateEmail />
								</ListItemIcon>
								<ListItemText
									inset
									primary="E-mail"
									secondary="galiadm@unwe.bg"
								/>
							</ListItem>
						</List>
					</Collapse>
				</List>

				<List
					component="nav"
					subheader={
						<ListSubheader component="div">
							<h1>Заместник-директор</h1>
						</ListSubheader>
					}
				>
					<ListItem button onClick={this.handleClick_4}>
						<ListItemIcon>
							<Avatar
								alt="Kristian Madjurov"
								src="/img/kristian.jpg"
								className={classes.avatar}
							/>
						</ListItemIcon>
						<ListItemText
							inset
							primary="Кристиян Маджуров"
							primaryTypographyProps={{ variant: "headline" }}
						/>
						{this.state.open5 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={this.state.open5} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<MeetingRoom />
								</ListItemIcon>
								<ListItemText inset primary="Кабинет" secondary="1010" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText inset primary="Телефон" secondary="02 8195 581" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<AlternateEmail />
								</ListItemIcon>
								<ListItemText
									inset
									primary="E-mail"
									secondary="k.madzhurov@unwe.bg"
								/>
							</ListItem>
						</List>
					</Collapse>
				</List>

				<List
					component="nav"
					subheader={
						<ListSubheader component="div" className={classes.title}>
							<h1>Гл. експерт звено "Кариерно и професионално развитие"</h1>
						</ListSubheader>
					}
				>
					<ListItem button onClick={this.handleClick_3}>
						<ListItemIcon>
							<Avatar
								alt="Anton Krustev"
								src="/img/krustev.jpg"
								className={classes.avatar}
							/>
						</ListItemIcon>
						<ListItemText
							inset
							primary="Антон Кръстев"
							primaryTypographyProps={{ variant: "headline" }}
						/>
						{this.state.open4 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={this.state.open4} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<MeetingRoom />
								</ListItemIcon>
								<ListItemText inset primary="Кабинет" secondary="1003A" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText inset primary="Телефон" secondary="02 8195 219" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<AlternateEmail />
								</ListItemIcon>
								<ListItemText
									inset
									primary="E-mail"
									secondary="anton.krustev@unwe.bg"
								/>
							</ListItem>
						</List>
					</Collapse>
				</List>

				<List
					component="nav"
					subheader={
						<ListSubheader component="div" className={classes.title}>
							<h1>Специализирано звено за практическо обучение</h1>
						</ListSubheader>
					}
				>
					<ListItem button onClick={this.handleClick_2}>
						<ListItemIcon>
							<Avatar
								alt="Mihaela Todorova-Dzikova"
								src="/img/mihaela.png"
								className={classes.avatar}
							/>
						</ListItemIcon>
						<ListItemText
							inset
							primary="Михаела Тодорова-Джикова"
							primaryTypographyProps={{ variant: "headline" }}
						/>
						{this.state.open3 ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					<Collapse in={this.state.open3} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<MeetingRoom />
								</ListItemIcon>
								<ListItemText inset primary="Кабинет" secondary="1003A" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<Phone />
								</ListItemIcon>
								<ListItemText inset primary="Телефон" secondary="02 8195 219" />
							</ListItem>
						</List>
						<List component="div" disablePadding>
							<ListItem className={classes.nested}>
								<ListItemIcon>
									<AlternateEmail />
								</ListItemIcon>
								<ListItemText
									inset
									primary="E-mail"
									secondary="mdzhikova@unwe.bg"
								/>
							</ListItem>
						</List>
					</Collapse>
				</List>
				<List
					component="nav"
					subheader={
						<ListSubheader component="div" className={classes.title}>
							<h1>Звено "Университет - бизнес"</h1>
						</ListSubheader>
					}
				/>
				<List
					component="nav"
					subheader={
						<ListSubheader
							component="div"
							className={classes.title}
                            sx={{ color: "#760e26" }}
							onClick={() => {
								window.location.href = "https://alumni.unwe.bg/";
							}}
						>
							<h1>Звено "Алумни - УНСС"</h1>
						</ListSubheader>
					}
				/>
			</div>
		);
	}
}

TeamList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeamList);
