import { authConstants } from "../constants/authenticationConstants";
import { getLoggedUser } from "../helpers";

const loggedUser = getLoggedUser();

const initialState = {
    isLoading: false,
    restrictFinishRegistration: true,
	loggedUser: loggedUser && loggedUser.token ? loggedUser : {}
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case authConstants.POST_SUCCESS:
			return {
				...state
			};
		case authConstants.POST_ERROR:
			return {
				...state,
				error: action.msg
			};
		case authConstants.LOGIN_BEGIN:
			return {
				...state,
				isLoading: true
			};
		case authConstants.LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				loggedUser: action.loggedUser
			};
		case authConstants.LOGIN_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.error
			};
		case authConstants.LOGOUT_SUCCESS:
			return {
				...state,
				loggedUser: {}
			};
		case authConstants.LOGOUT_ERROR:
			return {
				...state
            };
        case authConstants.EMAIL_EXISTS:
            return {
                ...state,
                restrictFinishRegistration: action.result
            };
        case authConstants.EIK_EXISTS:
            return {
                ...state,
                restrictFinishRegistration: action.result
            };
		default:
			return state;
	}
};
